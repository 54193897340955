const products = [
  {
    id: 0,
    item: 1,
    name: "CHIQUI NENES",
    image: require("./assets/images/productos-img/0/principal.jpg"),
    size: "2x2x2",
    age: "2-5 años",
    descripcion: "Con punchi.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [
      require("./assets/images/productos-img/0/principal.jpg"),
      require("./assets/images/productos-img/0/carousel2.jpg"),
    ],
  },
  {
    id: 1,
    item: 2,
    name: "CHIQUI NENAS",
    image: require("./assets/images/productos-img/1/principal.jpg"),
    size: "2x2x2",
    age: "2-5 años",
    descripcion: "Con punchi.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [
      require("./assets/images/productos-img/1/principal.jpg"),
      require("./assets/images/productos-img/1/carousel2.jpg"),
      require("./assets/images/productos-img/1/carousel3.jpg"),
      require("./assets/images/productos-img/1/carousel4.jpg"),
      require("./assets/images/productos-img/1/carousel5.jpg"),
    ],
  },
  {
    id: 2,
    item: 3,
    name: "BABY",
    image: require("./assets/images/productos-img/2/principal.jpg"),
    size: "2x2,50x2",
    age: "2-5 años",
    descripcion: "Sin tobogán. Dos punchis internos. Rampa de acceso.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [
      require("./assets/images/productos-img/2/principal.jpg"),
      require("./assets/images/productos-img/2/carousel2.jpg"),
      require("./assets/images/productos-img/2/carousel3.jpg"),
      require("./assets/images/productos-img/2/carousel4.jpg"),
      require("./assets/images/productos-img/2/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/84SSzemqDws"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 3,
    item: 4,
    name: "CASITA PELOTERO",
    image: require("./assets/images/productos-img/3/principal.jpg"),
    size: "2x2x2,30",
    age: "2-5 años",
    descripcion: "Con pelotitas y colchón inflable.",
    filtro: ["inflables", "chico", "2-5 años", "con pelotero"],
    carouselImages: [
      require("./assets/images/productos-img/3/principal.jpg"),
      require("./assets/images/productos-img/3/carousel2.jpg"),
      require("./assets/images/productos-img/3/carousel3.jpg"),
      require("./assets/images/productos-img/3/carousel4.jpg"),
      require("./assets/images/productos-img/3/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/0YrKm2h0dD4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 4,
    item: 5,
    name: "PELOTERO CANDY",
    image: require("./assets/images/productos-img/4/principal.jpg"),
    size: "2x2x2,50",
    age: "2-5 años",
    descripcion: "Con pelotitas.",
    filtro: ["inflables", "chico", "2-5 años", "con pelotero", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/4/principal.jpg"),
      require("./assets/images/productos-img/4/carousel2.jpg"),
      require("./assets/images/productos-img/4/carousel3.jpg"),
      require("./assets/images/productos-img/4/carousel4.jpg"),
      require("./assets/images/productos-img/4/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5TPqUtobwL4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 5,
    item: 6,
    name: "INFANTIL ",
    image: require("./assets/images/productos-img/5/principal.jpg"),
    size: "2,50x4x2",
    age: "2-5 años",
    plotter: true,
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "chico", "2-5 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/5/principal.jpg"),
      require("./assets/images/productos-img/5/carousel2.jpg"),
      require("./assets/images/productos-img/5/carousel3.jpg"),
      require("./assets/images/productos-img/5/carousel4.jpg"),
      require("./assets/images/productos-img/5/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/uV64dfBOFBY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 6,
    item: 7,
    name: "MAGICO",
    image: require("./assets/images/productos-img/6/principal.jpg"),
    size: "3x5x2,30",
    age: "2-5 años",
    plotter: true,
    descripcion: "Con tobogán y punchis.",
    filtro: ["inflables", "mediano", "2-5 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/6/principal.jpg"),
      require("./assets/images/productos-img/6/carousel2.jpg"),
      require("./assets/images/productos-img/6/carousel3.jpg"),
      require("./assets/images/productos-img/6/carousel4.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/GkGtj2H7cMs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 7,
    item: 8,
    name: "ELEFANTE",
    image: require("./assets/images/productos-img/7/principal.jpg"),
    size: "3,50x5x2,20",
    age: "2-9 años",
    descripcion: "Con tobogán, elefante y sapito.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años"],
    carouselImages: [
      require("./assets/images/productos-img/7/principal.jpg"),
      require("./assets/images/productos-img/7/carousel2.jpg"),
      require("./assets/images/productos-img/7/carousel3.jpg"),
      require("./assets/images/productos-img/7/carousel4.jpg"),
      require("./assets/images/productos-img/7/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/s3ZbqSJQWAs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 8,
    item: 9,
    name: "ALEGRIA",
    image: require("./assets/images/productos-img/8/principal.jpg"),
    size: "4x5",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con tobogán, punchis y caritas.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/8/principal.jpg"),
      require("./assets/images/productos-img/8/carousel2.jpg"),
      require("./assets/images/productos-img/8/carousel3.jpg"),
      require("./assets/images/productos-img/8/carousel4.jpg"),
      require("./assets/images/productos-img/8/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/G9rob2QeDqI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 9,
    item: 10,
    name: "FANTASIA",
    image: require("./assets/images/productos-img/9/principal.jpg"),
    size: "4x5x2,50",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/9/principal.jpg"),
      require("./assets/images/productos-img/9/carousel2.jpg"),
      require("./assets/images/productos-img/9/carousel3.jpg"),
      require("./assets/images/productos-img/9/carousel4.jpg"),
      require("./assets/images/productos-img/9/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/wCBWE18hyAI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 10,
    item: 11,
    name: "FUTBOL",
    image: require("./assets/images/productos-img/10/principal.jpg"),
    size: "3x5x3",
    age: "5-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchis.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/10/principal.jpg"),
      require("./assets/images/productos-img/10/carousel2.jpg"),
      require("./assets/images/productos-img/10/carousel3.jpg"),
      require("./assets/images/productos-img/10/carousel4.jpg"),
      require("./assets/images/productos-img/10/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2M9OOosOQxU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 11,
    item: 12,
    name: "UNICORNIOS Y PONYS",
    image: require("./assets/images/productos-img/11/principal.jpg"),
    size: "4x5x3",
    age: "5-9 años",
    descripcion: "Con tobogán, 2 ponys internos y rampa de acceso.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/11/principal.jpg"),
      require("./assets/images/productos-img/11/carousel2.jpg"),
      require("./assets/images/productos-img/11/carousel3.jpg"),
      require("./assets/images/productos-img/11/carousel4.jpg"),
      require("./assets/images/productos-img/11/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/x-j6iiEHMtk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 12,
    item: 13,
    name: "BOSQUE ENCANTADO",
    image: require("./assets/images/productos-img/12/principal.jpg"),
    size: "4x5x3",
    age: "5-9 años",
    descripcion: "Con tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/12/principal.jpg"),
      require("./assets/images/productos-img/12/carousel2.jpg"),
      require("./assets/images/productos-img/12/carousel3.jpg"),
      require("./assets/images/productos-img/12/carousel4.jpg"),
      require("./assets/images/productos-img/12/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rzxXs65k85w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 13,
    item: 14,
    name: "PRINCESAS",
    image: require("./assets/images/productos-img/13/principal.jpg"),
    size: "4x4x3,10",
    age: "5-9 años",
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/13/principal.jpg"),
      require("./assets/images/productos-img/13/carousel2.jpg"),
      require("./assets/images/productos-img/13/carousel3.jpg"),
      require("./assets/images/productos-img/13/carousel4.jpg"),
      require("./assets/images/productos-img/13/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/R3D46nXvW1Y"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 14,
    item: 15,
    name: "MICKEY Y MINNIE",
    image: require("./assets/images/productos-img/14/principal.jpg"),
    size: "4x5x3,60",
    age: "5-9 años",
    descripcion: "Con tobogán e imágenes gigantes de Mickey y Minnie.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/14/principal.jpg"),
      require("./assets/images/productos-img/14/carousel2.jpg"),
      require("./assets/images/productos-img/14/carousel3.jpg"),
      require("./assets/images/productos-img/14/carousel4.jpg"),
      require("./assets/images/productos-img/14/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-pGA0XeH6M8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 15,
    item: 16,
    name: "CARS",
    image: require("./assets/images/productos-img/15/principal.jpg"),
    size: "3x5x2,30",
    age: "5-9 años",
    descripcion: "Con tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/15/principal.jpg"),
      require("./assets/images/productos-img/15/carousel2.jpg"),
      require("./assets/images/productos-img/15/carousel3.jpg"),
      require("./assets/images/productos-img/15/carousel4.jpg"),
      require("./assets/images/productos-img/15/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/THzPoP9ROOk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 16,
    item: 17,
    name: "SUPER HEROES",
    image: require("./assets/images/productos-img/16/principal.jpg"),
    size: "4x5x3,60",
    age: "5-9 años",
    descripcion: "Con área para saltar y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/16/principal.jpg"),
      require("./assets/images/productos-img/16/carousel2.jpg"),
      require("./assets/images/productos-img/16/carousel3.jpg"),
      require("./assets/images/productos-img/16/carousel4.jpg"),
      require("./assets/images/productos-img/16/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/jPrYgxMN9_8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 17,
    item: 18,
    name: "HOMBRE ARAÑA",
    image: require("./assets/images/productos-img/17/principal.jpg"),
    size: "4x5x3,50",
    age: "5-9 años",
    descripcion: "Con tobogán curvo y punchis.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/17/principal.jpg"),
      require("./assets/images/productos-img/17/carousel2.jpg"),
      require("./assets/images/productos-img/17/carousel3.jpg"),
      require("./assets/images/productos-img/17/carousel4.jpg"),
      require("./assets/images/productos-img/17/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/UlQAVmepKzI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 18,
    item: 19,
    name: "ARCOIRIS",
    image: require("./assets/images/productos-img/18/principal.jpg"),
    size: "3x6x3",
    age: "5-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchis.",
    filtro: ["inflables", "mediano", "5-9 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/18/principal.jpg"),
      require("./assets/images/productos-img/18/carousel2.jpg"),
      require("./assets/images/productos-img/18/carousel3.jpg"),
      require("./assets/images/productos-img/18/carousel4.jpg"),
      require("./assets/images/productos-img/18/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2N-w1QEiuIM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 19,
    item: 20,
    name: "CAMION",
    image: require("./assets/images/productos-img/19/principal.jpg"),
    size: "3x6x2,50",
    age: "5-9 años",
    descripcion: "Con trepadora, tuerca interna, tobogán y rampa de acceso.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/19/principal.jpg"),
      require("./assets/images/productos-img/19/carousel2.jpg"),
      require("./assets/images/productos-img/19/carousel3.jpg"),
      require("./assets/images/productos-img/19/carousel4.jpg"),
      require("./assets/images/productos-img/19/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/75pwgos6Ul8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 20,
    item: 21,
    name: "TREN THOMAS ",
    image: require("./assets/images/productos-img/20/principal.jpg"),
    size: "3x6x2,50",
    age: "5-9 años",
    descripcion: "Con trepadora, tobogán y área para saltar.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/20/principal.jpg"),
      require("./assets/images/productos-img/20/carousel2.jpg"),
      require("./assets/images/productos-img/20/carousel3.jpg"),
      require("./assets/images/productos-img/20/carousel4.jpg"),
      require("./assets/images/productos-img/20/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ezYgl7jX7lI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 21,
    item: 22,
    name: "DINO",
    image: require("./assets/images/productos-img/21/principal.jpg"),
    size: "4x4x3,50",
    age: "5-9 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/21/principal.jpg"),
      require("./assets/images/productos-img/21/carousel2.jpg"),
      require("./assets/images/productos-img/21/carousel3.jpg"),
      require("./assets/images/productos-img/21/carousel4.jpg"),
      require("./assets/images/productos-img/21/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/8bNm_gVAkf0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 22,
    item: 23,
    name: "CANDY",
    image: require("./assets/images/productos-img/22/principal.jpg"),
    size: "4x4x3,50",
    age: "5-9 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "exclusivo", "tematico"],
    carouselImages: [
      require("./assets/images/productos-img/22/principal.jpg"),
      require("./assets/images/productos-img/22/carousel2.jpg"),
      require("./assets/images/productos-img/22/carousel3.jpg"),
      require("./assets/images/productos-img/22/carousel4.jpg"),
      require("./assets/images/productos-img/22/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/Flai339oiII"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 23,
    item: [22, 4],
    name: "DINO CON PELOTERO",
    image: require("./assets/images/productos-img/23/principal.jpg"),
    size: "6x4x3,50",
    age: "2-9 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/23/principal.jpg"),
      require("./assets/images/productos-img/23/carousel2.jpg"),
      require("./assets/images/productos-img/23/carousel3.jpg"),
      require("./assets/images/productos-img/23/carousel4.jpg"),
      require("./assets/images/productos-img/23/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/thHKDpD6ayM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 24,
    item: [23, 5],
    name: "CANDY CON PELOTERO",
    image: require("./assets/images/productos-img/24/principal.jpg"),
    size: "6x4x3,50",
    age: "2-9 años",
    descripcion: "Con pelotero, área para saltar. Trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      require("./assets/images/productos-img/24/principal.jpg"),
      require("./assets/images/productos-img/24/carousel2.jpg"),
      require("./assets/images/productos-img/24/carousel3.jpg"),
      require("./assets/images/productos-img/24/carousel4.jpg"),
      require("./assets/images/productos-img/24/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/AXnb6lg5i3I"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 25,
    item: 24,
    name: "JUNGLA",
    image: require("./assets/images/productos-img/25/principal.jpg"),
    size: "4x6x3,30",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con pelotero, área para saltar. Tobogán y rampa de acceso.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "plotter",
    ],
    carouselImages: [
      require("./assets/images/productos-img/25/principal.jpg"),
      require("./assets/images/productos-img/25/carousel2.jpg"),
      require("./assets/images/productos-img/25/carousel3.jpg"),
      require("./assets/images/productos-img/25/carousel4.jpg"),
      require("./assets/images/productos-img/25/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/l2oJEXVeZXY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 26,
    item: 25,
    name: "CASTILLO FIESTA",
    image: require("./assets/images/productos-img/26/principal.jpg"),
    size: "4x6x3,20",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con pelotero, área para saltar y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "plotter",
    ],
    carouselImages: [
      require("./assets/images/productos-img/26/principal.jpg"),
      require("./assets/images/productos-img/26/carousel2.jpg"),
      require("./assets/images/productos-img/26/carousel3.jpg"),
      require("./assets/images/productos-img/26/carousel4.jpg"),
      require("./assets/images/productos-img/26/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZMIXlUd-Shw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 27,
    item: 26,
    name: "TREPADORA",
    image: require("./assets/images/productos-img/27/principal.jpg"),
    size: "3x7,50x2,70",
    age: "7-13 años",
    descripcion: "Con área para saltar. Trepadora y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/27/principal.jpg"),
      require("./assets/images/productos-img/27/carousel2.jpg"),
      require("./assets/images/productos-img/27/carousel3.jpg"),
      require("./assets/images/productos-img/27/carousel4.jpg"),
      require("./assets/images/productos-img/27/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FJe5PmVKhD4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  // {
  //   id: 28,
  //   item: 27,
  //   name: "CITY KIDS",
  //   image: require("./assets/images/productos-img/28/principal.jpg"),
  //   size: "4x4x2,30",
  //   age: "5-9 años",
  //   descripcion: "Con túnel, trepadora, tobogán y punchis.",
  //   filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
  //   carouselImages: [
  //
  //     require("./assets/images/productos-img/28/principal.jpg"),
  //     require("./assets/images/productos-img/28/carousel2.jpg"),
  //     require("./assets/images/productos-img/28/carousel3.jpg"),
  //     require("./assets/images/productos-img/28/carousel4.jpg"),
  //     require("./assets/images/productos-img/28/carousel5.jpg"),
  //   ],
  // },
  {
    id: 29,
    item: 28,
    name: "PIRATAS",
    image: require("./assets/images/productos-img/29/principal.jpg"),
    size: "5x5x3,80",
    age: "7-13 años",
    descripcion: "Con obstáculos, túnel trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "5-9 años",
      "9-13 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/29/principal.jpg"),
      require("./assets/images/productos-img/29/carousel2.jpg"),
      require("./assets/images/productos-img/29/carousel3.jpg"),
      require("./assets/images/productos-img/29/carousel4.jpg"),
      require("./assets/images/productos-img/29/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/EL2A7kbXKwc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 30,
    item: 29,
    name: "CANCHA DE FUTBOL 4x6",
    image: require("./assets/images/productos-img/30/principal.jpg"),
    size: "6x4x4",
    age: "5-9 años",
    descripcion:
      "Con colchón inflable. No incluye pelota. Cancha de fútbol, básquet y vóley.",
    filtro: ["inflables", "mediano", "5-9 años", "deporte"],
    carouselImages: [
      require("./assets/images/productos-img/30/principal.jpg"),
      require("./assets/images/productos-img/30/carousel2.jpg"),
      require("./assets/images/productos-img/30/carousel3.jpg"),
      require("./assets/images/productos-img/30/carousel4.jpg"),
      require("./assets/images/productos-img/30/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/YBp5UhD1mBw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 31,
    item: 30,
    name: "CANCHA DE FUTBOL 4x9",
    image: require("./assets/images/productos-img/31/principal.jpg"),
    size: "4x9",
    age: "7-13 años",
    plotter: true,
    descripcion:
      "Sin colchón inflable. No incluye pelota. Cancha de fútbol, básquet, vóley y fútbol tenis.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      require("./assets/images/productos-img/31/principal.jpg"),
      require("./assets/images/productos-img/31/carousel2.jpg"),
      require("./assets/images/productos-img/31/carousel3.jpg"),
      require("./assets/images/productos-img/31/carousel4.jpg"),
      require("./assets/images/productos-img/31/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/pAvekx8YLWE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 32,
    item: 30,
    name: "CANCHA METEGOL HUMANO ",
    image: require("./assets/images/productos-img/32/principal.jpg"),
    size: "4x9",
    age: "7-13 años",
    descripcion:
      "Piso de lona. Sin colchón inflable. Cancha de fútbol, básquet, fútbol tenis, vóley y metegol humano. No incluye pelota.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      require("./assets/images/productos-img/32/principal.jpg"),
      require("./assets/images/productos-img/32/carousel2.jpg"),
      require("./assets/images/productos-img/32/carousel3.jpg"),
      require("./assets/images/productos-img/32/carousel4.jpg"),
      require("./assets/images/productos-img/32/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/svD8RciOM94"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 33,
    item: 31,
    name: "ARCO ATAJA PENALES",
    image: require("./assets/images/productos-img/33/principal.jpg"),
    size: "4x5x3",
    age: "5-13 años",
    descripcion: "No incluye pelota.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      require("./assets/images/productos-img/33/principal.jpg"),
      require("./assets/images/productos-img/33/carousel2.jpg"),
      require("./assets/images/productos-img/33/carousel3.jpg"),
      require("./assets/images/productos-img/33/carousel4.jpg"),
      require("./assets/images/productos-img/33/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1B9RS1gpSJw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 34,
    item: 31,
    name: "ARCO CON PANEL",
    image: require("./assets/images/productos-img/34/principal.jpg"),
    size: "4x5x3",
    age: "5-13 años",
    descripcion:
      "Con lona para patear y embocar en los agujeros. No incluye pelota.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      require("./assets/images/productos-img/34/principal.jpg"),
      require("./assets/images/productos-img/34/carousel2.jpg"),
      require("./assets/images/productos-img/34/carousel3.jpg"),
      require("./assets/images/productos-img/34/carousel4.jpg"),
      require("./assets/images/productos-img/34/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/0UR-DitRNMQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 35,
    item: 32,
    name: "MURO 1",
    image: require("./assets/images/productos-img/35/principal.jpg"),
    size: "3x6x4,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/35/principal.jpg"),
      require("./assets/images/productos-img/35/carousel2.jpg"),
      require("./assets/images/productos-img/35/carousel3.jpg"),
      require("./assets/images/productos-img/35/carousel4.jpg"),
      require("./assets/images/productos-img/35/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1BtgDbCPOXg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 36,
    item: 33,
    name: "MURO 2",
    image: require("./assets/images/productos-img/36/principal.jpg"),
    size: "4x6x4,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/36/principal.jpg"),
      require("./assets/images/productos-img/36/carousel2.jpg"),
      require("./assets/images/productos-img/36/carousel3.jpg"),
      require("./assets/images/productos-img/36/carousel4.jpg"),
      require("./assets/images/productos-img/36/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/AWJsTXcJHrc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 37,
    item: 34,
    name: "SUPER ESCALADOR",
    image: require("./assets/images/productos-img/37/principal.jpg"),
    size: "6x5x5",
    age: "5-13 años",
    descripcion: "Con doble trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/37/principal.jpg"),
      require("./assets/images/productos-img/37/carousel2.jpg"),
      require("./assets/images/productos-img/37/carousel3.jpg"),
      require("./assets/images/productos-img/37/carousel4.jpg"),
      require("./assets/images/productos-img/37/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/aNzyfymTQ-M"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 38,
    item: 35,
    name: "ESCALADOR MULTICOLOR",
    image: require("./assets/images/productos-img/38/principal.jpg"),
    size: "3x5x3,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/38/principal.jpg"),
      require("./assets/images/productos-img/38/carousel2.jpg"),
      require("./assets/images/productos-img/38/carousel3.jpg"),
      require("./assets/images/productos-img/38/carousel4.jpg"),
      require("./assets/images/productos-img/38/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/NxLnVudkl_w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 39,
    item: 36,
    name: "ESCALADOR SURF",
    image: require("./assets/images/productos-img/39/principal.jpg"),
    size: "6,20x4x4,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/39/principal.jpg"),
      require("./assets/images/productos-img/39/carousel2.jpg"),
      require("./assets/images/productos-img/39/carousel3.jpg"),
      require("./assets/images/productos-img/39/carousel4.jpg"),
      require("./assets/images/productos-img/39/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/4MtYxWJX5DU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 40,
    item: 37,
    name: "MUNDO MARINO",
    image: require("./assets/images/productos-img/40/principal.jpg"),
    size: "5x5,50x4",
    age: "5-13 años",
    descripcion: "Con muro y tobogán.",
    filtro: [
      "inflables",
      "grande",
      "5-9 años",
      "9-13 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/40/principal.jpg"),
      require("./assets/images/productos-img/40/carousel2.jpg"),
      require("./assets/images/productos-img/40/carousel3.jpg"),
      require("./assets/images/productos-img/40/carousel4.jpg"),
      require("./assets/images/productos-img/40/carousel5.jpg"),
    ],
  },
  {
    id: 41,
    item: 38,
    name: "AVENTURA 1",
    image: require("./assets/images/productos-img/41/principal.jpg"),
    size: "6x6x4",
    age: "5-13 años",
    descripcion: "Con tobogán curvo.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/41/principal.jpg"),
      require("./assets/images/productos-img/41/carousel2.jpg"),
      require("./assets/images/productos-img/41/carousel3.jpg"),
      require("./assets/images/productos-img/41/carousel4.jpg"),
      require("./assets/images/productos-img/41/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/wiL3w10ZR_k"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 42,
    item: 39,
    name: "AVENTURA 2",
    image: require("./assets/images/productos-img/42/principal.jpg"),
    size: "6x5x4,50",
    age: "5-13 años",
    descripcion: "Con tobogán curvo.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/42/principal.jpg"),
      require("./assets/images/productos-img/42/carousel2.jpg"),
      require("./assets/images/productos-img/42/carousel3.jpg"),
      require("./assets/images/productos-img/42/carousel4.jpg"),
      require("./assets/images/productos-img/42/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/fXJ7cgm5z0A"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 43,
    item: 40,
    name: "SALTARIN",
    image: require("./assets/images/productos-img/43/principal.jpg"),
    size: "2,5X3,5X2",
    age: "2-5 años",
    plotter: true,
    descripcion: "Área de salto.",
    filtro: ["inflables", "chico", "2-5 años", "plotter"],
    carouselImages: [
      require("./assets/images/productos-img/43/principal.jpg"),
      require("./assets/images/productos-img/43/carousel2.jpg"),
      require("./assets/images/productos-img/43/carousel3.jpg"),
      require("./assets/images/productos-img/43/carousel4.jpg"),
      require("./assets/images/productos-img/43/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/8N9c88myKSo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 44,
    item: 41,
    name: "PELOTERO BLANCO",
    image: require("./assets/images/productos-img/44/principal.jpg"),
    size: "2,5x2x1,70",
    age: "2-5 años",
    descripcion: "Con pelotitas color pastel.",
    filtro: [
      "inflables",
      "chico",
      "con pelotero",
      "2-5 años",
      "exclusivo",
      "blanco",
    ],
    carouselImages: [
      require("./assets/images/productos-img/44/principal.jpg"),
      require("./assets/images/productos-img/44/carousel2.jpg"),
      require("./assets/images/productos-img/44/carousel3.jpg"),
      require("./assets/images/productos-img/44/carousel4.jpg"),
      require("./assets/images/productos-img/44/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2UZnA0XmHvI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 45,
    item: 42,
    name: "BLANCO",
    image: require("./assets/images/productos-img/45/principal.jpg"),
    size: "3,70x3x3",
    age: "2-9 años",
    descripcion: "Puede agregar globos y puede agregar vinilo.",
    filtro: [
      "inflables",
      "2-5 años",
      "5-9 años",
      "mediano",
      "exclusivo",
      "blanco",
    ],
    carouselImages: [
      require("./assets/images/productos-img/45/principal.jpg"),
      require("./assets/images/productos-img/45/carousel2.jpg"),
      require("./assets/images/productos-img/45/carousel3.jpg"),
      require("./assets/images/productos-img/45/carousel4.jpg"),
      require("./assets/images/productos-img/45/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/GFLnhQSDoJs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 46,
    item: 43,
    name: "SAFARI",
    image: require("./assets/images/productos-img/46/principal.jpg"),
    size: "4x4x5",
    age: "5-9 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico", "exclusivo"],
    carouselImages: [
      require("./assets/images/productos-img/46/principal.jpg"),
      require("./assets/images/productos-img/46/carousel2.jpg"),
      require("./assets/images/productos-img/46/carousel3.jpg"),
      require("./assets/images/productos-img/46/carousel4.jpg"),
      require("./assets/images/productos-img/46/carousel5.jpg"),
    ],
  },
  {
    id: 47,
    item: [42, 41],
    name: "BLANCO CON PELOTERO",
    image: require("./assets/images/productos-img/47/principal.jpg"),
    size: "3,70x5x3",
    age: "2-9 años",
    descripcion:
      "Puede agregar globos y puede agregar vinilo. Con pelotitas color pastel.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "blanco",
    ],
    carouselImages: [
      require("./assets/images/productos-img/47/principal.jpg"),
      require("./assets/images/productos-img/47/carousel2.jpg"),
      require("./assets/images/productos-img/47/carousel3.jpg"),
      require("./assets/images/productos-img/47/carousel4.jpg"),
      require("./assets/images/productos-img/47/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/H5ptufxaz8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 48,
    item: [43, 4],
    name: "SAFARI CON CASITA PELOTERO",
    image: require("./assets/images/productos-img/48/principal.jpg"),
    size: "6x4x5",
    age: "2-9 años",
    descripcion: "Con pelotero, área para saltar, trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/48/principal.jpg"),
      require("./assets/images/productos-img/48/carousel2.jpg"),
      require("./assets/images/productos-img/48/carousel3.jpg"),
      require("./assets/images/productos-img/48/carousel4.jpg"),
      require("./assets/images/productos-img/48/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/NQf1kK2AhL8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 49,
    item: [43, 204],
    name: "SAFARI CON TORTUGA PELOTERO",
    image: require("./assets/images/productos-img/49/principal.jpg"),
    size: "4x5,50x5",
    age: "2-9 años",
    descripcion: "Con pelotero, área para saltar, trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/49/principal.jpg"),
      require("./assets/images/productos-img/49/carousel2.jpg"),
      require("./assets/images/productos-img/49/carousel3.jpg"),
      require("./assets/images/productos-img/49/carousel4.jpg"),
      require("./assets/images/productos-img/49/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/Xa0mdn9VVqU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 50,
    item: [43, 4, 204],
    name: "SAFARI CON DOBLE PELOTERO",
    image: require("./assets/images/productos-img/50/principal.jpg"),
    size: "6x5,50x5",
    age: "2-9 años",
    descripcion: "Con doble pelotero, área para saltar, trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
    ],
    carouselImages: [
      require("./assets/images/productos-img/50/principal.jpg"),
      require("./assets/images/productos-img/50/carousel2.jpg"),
      require("./assets/images/productos-img/50/carousel3.jpg"),
      require("./assets/images/productos-img/50/carousel4.jpg"),
      require("./assets/images/productos-img/50/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/w9SI2_HeH1o"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 51,
    item: 44,
    name: "BURBUJA INFLABLE CRISTAL",
    image: require("./assets/images/productos-img/51/principal.jpg"),
    size: "3,50x3,50",
    age: "2-9 años",
    descripcion:
      "Burbujua inflable cristal para saltar, con globos en su interior.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "exclusivo",
      "blanco",
    ],
    carouselImages: [
      require("./assets/images/productos-img/51/principal.jpg"),
      require("./assets/images/productos-img/51/carousel2.jpg"),
    ],
    consultar: true,
  },
  {
    id: 100,
    item: 101,
    name: "CARRERA DE OBSTACULOS 1",
    image: require("./assets/images/productos-img/100/principal.jpg"),
    size: "4x7x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/100/principal.jpg"),
      require("./assets/images/productos-img/100/carousel2.jpg"),
      require("./assets/images/productos-img/100/carousel3.jpg"),
      require("./assets/images/productos-img/100/carousel4.jpg"),
      require("./assets/images/productos-img/100/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FmNomNGzfo4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 101,
    item: 102,
    name: "CARRERA DE OBSTACULOS 2",
    image: require("./assets/images/productos-img/101/principal.jpg"),
    size: "4x7x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/101/principal.jpg"),
      require("./assets/images/productos-img/101/carousel2.jpg"),
      require("./assets/images/productos-img/101/carousel3.jpg"),
      require("./assets/images/productos-img/101/carousel4.jpg"),
      require("./assets/images/productos-img/101/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/TK_nLvjsNzg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 102,
    item: 106,
    name: "CARRERA DE OBSTACULOS 3",
    image: require("./assets/images/productos-img/102/principal.jpg"),
    size: "4x8x4,50",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/102/principal.jpg"),
      require("./assets/images/productos-img/102/carousel2.jpg"),
      require("./assets/images/productos-img/102/carousel3.jpg"),
      require("./assets/images/productos-img/102/carousel4.jpg"),
      require("./assets/images/productos-img/102/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gbpEPvnxMzA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 103,
    item: [101, 102],
    name: "CARRERA DE OBSTACULOS 4",
    image: require("./assets/images/productos-img/103/principal.jpg"),
    size: "4x14x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/103/principal.jpg"),
      require("./assets/images/productos-img/103/carousel2.jpg"),
      require("./assets/images/productos-img/103/carousel3.jpg"),
      require("./assets/images/productos-img/103/carousel4.jpg"),
      require("./assets/images/productos-img/103/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/fe0yOvt8LUA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 104,
    item: [33, 103],
    name: "CARRERA DE OBSTACULOS 5",
    image: require("./assets/images/productos-img/104/principal.jpg"),
    size: "4x12x4,50",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/104/principal.jpg"),
      require("./assets/images/productos-img/104/carousel2.jpg"),
      require("./assets/images/productos-img/104/carousel3.jpg"),
      require("./assets/images/productos-img/104/carousel4.jpg"),
      require("./assets/images/productos-img/104/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gUfEtBxnZCs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 105,
    item: [26, 32],
    name: "CARRERA DE OBSTACULOS 6",
    image: require("./assets/images/productos-img/105/principal.jpg"),
    size: "4x14x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/105/principal.jpg"),
      require("./assets/images/productos-img/105/carousel2.jpg"),
    ],
  },
  {
    id: 106,
    item: [26, 102],
    name: "CARRERA DE OBSTACULOS 7",
    image: require("./assets/images/productos-img/106/principal.jpg"),
    size: "4x14x4,50",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/106/principal.jpg"),
      require("./assets/images/productos-img/106/carousel2.jpg"),
      require("./assets/images/productos-img/106/carousel3.jpg"),
      require("./assets/images/productos-img/106/carousel4.jpg"),
      require("./assets/images/productos-img/106/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/lsFXrFU8FdM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 107,
    item: [36, 103],
    name: "CARRERA DESAFIO 1",
    image: require("./assets/images/productos-img/107/principal.jpg"),
    size: "13,5x4x4,50",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/107/principal.jpg"),
      require("./assets/images/productos-img/107/carousel2.jpg"),
      require("./assets/images/productos-img/107/carousel3.jpg"),
      require("./assets/images/productos-img/107/carousel4.jpg"),
      require("./assets/images/productos-img/107/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9Ta9h5jAcYU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 108,
    item: [34, 103],
    name: "CARRERA DESAFIO 2",
    image: require("./assets/images/productos-img/108/principal.jpg"),
    size: "13x4x5",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/108/principal.jpg"),
      require("./assets/images/productos-img/108/carousel2.jpg"),
      require("./assets/images/productos-img/108/carousel3.jpg"),
      require("./assets/images/productos-img/108/carousel4.jpg"),
      require("./assets/images/productos-img/108/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/UjZKy9On4BQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 109,
    item: [36, 106],
    name: "CARRERA DESAFIO 3",
    image: require("./assets/images/productos-img/109/principal.jpg"),
    size: "12,5x4x4,50",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/109/principal.jpg"),
      require("./assets/images/productos-img/109/carousel2.jpg"),
      require("./assets/images/productos-img/109/carousel3.jpg"),
      require("./assets/images/productos-img/109/carousel4.jpg"),
      require("./assets/images/productos-img/109/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rQiDBJtTeFY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 110,
    item: [32, 34],
    name: "CARRERA DESAFIO 4",
    image: require("./assets/images/productos-img/110/principal.jpg"),
    size: "12x4x5",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/110/principal.jpg"),
      require("./assets/images/productos-img/110/carousel2.jpg"),
      require("./assets/images/productos-img/110/carousel3.jpg"),
      require("./assets/images/productos-img/110/carousel4.jpg"),
      require("./assets/images/productos-img/110/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/m9XkivhhQ5w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 111,
    item: [33, 36, 103],
    name: "CARRERA DESAFIO PRO 1",
    image: require("./assets/images/productos-img/111/principal.jpg"),
    size: "18,50x4",
    age: "6-13 años",
    descripcion: "Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/111/principal.jpg"),
      require("./assets/images/productos-img/111/carousel2.jpg"),
      require("./assets/images/productos-img/111/carousel3.jpg"),
      require("./assets/images/productos-img/111/carousel4.jpg"),
      require("./assets/images/productos-img/111/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/vcdTQlNGDLQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 112,
    item: [33, 34, 103],
    name: "CARRERA DESAFIO PRO 2",
    image: require("./assets/images/productos-img/112/principal.jpg"),
    size: "18x4",
    age: "6-13 años",
    descripcion: "Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/112/principal.jpg"),
      require("./assets/images/productos-img/112/carousel2.jpg"),
      require("./assets/images/productos-img/112/carousel3.jpg"),
      require("./assets/images/productos-img/112/carousel4.jpg"),
      require("./assets/images/productos-img/112/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FzdbWAjy53M"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 113,
    item: [101, 102, 104],
    name: "SUPER CARRERA 1",
    image: require("./assets/images/productos-img/113/principal.jpg"),
    size: "7x10",
    age: "6-13 años",
    descripcion:
      "Con 25 metros de carrera continua. Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/113/principal.jpg"),
      require("./assets/images/productos-img/113/carousel2.jpg"),
      require("./assets/images/productos-img/113/carousel3.jpg"),
      require("./assets/images/productos-img/113/carousel4.jpg"),
      require("./assets/images/productos-img/113/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/XCcJ4nZmTOA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 114,
    item: [105, 33, 103],
    name: "SUPER CARRERA 2",
    image: require("./assets/images/productos-img/114/principal.jpg"),
    size: "7x10",
    age: "6-13 años",
    descripcion:
      "Con 25 metros de carrera continua. Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/114/principal.jpg"),
      require("./assets/images/productos-img/114/carousel2.jpg"),
      require("./assets/images/productos-img/114/carousel3.jpg"),
      require("./assets/images/productos-img/114/carousel4.jpg"),
      require("./assets/images/productos-img/114/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gZf5fT9xLl4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 115,
    item: [32, 105, 106],
    name: "SUPER CARRERA 3",
    image: require("./assets/images/productos-img/115/principal.jpg"),
    size: "7x10",
    age: "6-13 años",
    descripcion:
      "Con 25 metros de carrera continua. Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/115/principal.jpg"),
      require("./assets/images/productos-img/115/carousel2.jpg"),
      require("./assets/images/productos-img/115/carousel3.jpg"),
      require("./assets/images/productos-img/115/carousel4.jpg"),
      require("./assets/images/productos-img/115/carousel5.jpg"),
    ],
  },
  {
    id: 116,
    item: [104, 103, 33, 102, 101],
    name: "SUPER CARRERA PRO 1",
    image: require("./assets/images/productos-img/116/principal.jpg"),
    size: "7x16",
    age: "6-13 años",
    descripcion:
      "Con 40 metros de carrera continua. Motor consumo: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/116/principal.jpg"),
      require("./assets/images/productos-img/116/carousel2.jpg"),
      require("./assets/images/productos-img/116/carousel3.jpg"),
      require("./assets/images/productos-img/116/carousel4.jpg"),
      require("./assets/images/productos-img/116/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/n9MvSKOhNaA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 117,
    item: [105, 103, 33, 32, 26],
    name: "SUPER CARRERA PRO 2",
    image: require("./assets/images/productos-img/117/principal.jpg"),
    size: "7x16",
    age: "6-13 años",
    descripcion:
      "Con 40 metros de carrera continua. Motor consumo: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/117/principal.jpg"),
      require("./assets/images/productos-img/117/carousel2.jpg"),
      require("./assets/images/productos-img/117/carousel3.jpg"),
      require("./assets/images/productos-img/117/carousel4.jpg"),
      require("./assets/images/productos-img/117/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/Z-NB2YKZNyU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 118,
    item: [32, 101, 102, 104, 106],
    name: "SUPER CARRERA PRO 3",
    image: require("./assets/images/productos-img/118/principal.jpg"),
    size: "7x16",
    age: "6-13 años",
    descripcion:
      "Con 40 metros de carrera continua. Motor consumo: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/118/principal.jpg"),
      require("./assets/images/productos-img/118/carousel2.jpg"),
      require("./assets/images/productos-img/118/carousel3.jpg"),
      require("./assets/images/productos-img/118/carousel4.jpg"),
      require("./assets/images/productos-img/118/carousel5.jpg"),
    ],
  },
  {
    id: 119,
    item: [104, 105, 103, 33],
    name: "CARRERA INFINITA",
    image: require("./assets/images/productos-img/119/principal.jpg"),
    size: "7x13",
    age: "6-13 años",
    descripcion:
      "Con 40 metros de carrera continua. Motor consumo: 24 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/119/principal.jpg"),
      require("./assets/images/productos-img/119/carousel2.jpg"),
      require("./assets/images/productos-img/119/carousel3.jpg"),
      require("./assets/images/productos-img/119/carousel4.jpg"),
      require("./assets/images/productos-img/119/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/WjZDU45AFKQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 120,
    item: [104, 105, 103, 33, 102, 101],
    name: "MEGA CARRERA INFINITA",
    image: require("./assets/images/productos-img/120/principal.jpg"),
    size: "7x19",
    age: "6-13 años",
    descripcion:
      "Con 50 metros de carrera continua. Motor consumo: 36 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/120/principal.jpg"),
      require("./assets/images/productos-img/120/carousel2.jpg"),
      require("./assets/images/productos-img/120/carousel3.jpg"),
      require("./assets/images/productos-img/120/carousel4.jpg"),
      require("./assets/images/productos-img/120/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZvA2loVURWE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 121,
    item: [104, 105, 103, 33, 102, 101],
    name: "MEGA CARRERA INFINITA 2",
    image: require("./assets/images/productos-img/121/principal.jpg"),
    size: "13x13",
    age: "6-13 años",
    descripcion:
      "Con 50 metros de carrera continua. Motor consumo: 24 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      require("./assets/images/productos-img/121/principal.jpg"),
      require("./assets/images/productos-img/121/carousel2.jpg"),
      require("./assets/images/productos-img/121/carousel3.jpg"),
      require("./assets/images/productos-img/121/carousel4.jpg"),
      require("./assets/images/productos-img/121/carousel5.jpg"),
    ],
  },
  {
    id: 122,
    item: [104, 103, 33, 102, 101, 26, 32],
    name: "CARRERA SUPER MEGA PRO",
    image: require("./assets/images/productos-img/122/principal.jpg"),
    size: "7x25",
    age: "6-13 años",
    descripcion:
      "Con 60 metros de carrera continua. Motor consumo: 42 amperes.",
    filtro: ["carreras", "grande"],
    carouselImages: [
      require("./assets/images/productos-img/122/principal.jpg"),
      require("./assets/images/productos-img/122/carousel2.jpg"),
      require("./assets/images/productos-img/122/carousel3.jpg"),
      require("./assets/images/productos-img/122/carousel4.jpg"),
      require("./assets/images/productos-img/122/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1CWsEXt3-sU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 123,
    item: [32, 106],
    name: "CARRERA DE OBSTACULOS 8",
    image: require("./assets/images/productos-img/123/principal.jpg"),
    size: "4x14x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande"],
    carouselImages: [
      require("./assets/images/productos-img/123/principal.jpg"),
      require("./assets/images/productos-img/123/carousel2.jpg"),
      require("./assets/images/productos-img/123/carousel3.jpg"),
      require("./assets/images/productos-img/123/carousel4.jpg"),
      require("./assets/images/productos-img/123/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/DnC-5jZpVgw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 124,
    item: [26, 106],
    name: "CARRERA DE OBSTACULOS 9",
    image: require("./assets/images/productos-img/124/principal.jpg"),
    size: "4x14x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande"],
    carouselImages: [
      require("./assets/images/productos-img/124/principal.jpg"),
      require("./assets/images/productos-img/124/carousel2.jpg"),
      require("./assets/images/productos-img/124/carousel3.jpg"),
      require("./assets/images/productos-img/124/carousel4.jpg"),
      require("./assets/images/productos-img/124/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/DPLK06MDNjw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 125,
    item: 107,
    name: "CARRERA DE OBSTACULOS 10",
    image: require("./assets/images/productos-img/125/principal.jpg"),
    size: "4x7x4",
    age: "6-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande"],
    carouselImages: [
      require("./assets/images/productos-img/125/principal.jpg"),
      require("./assets/images/productos-img/125/carousel2.jpg"),
      require("./assets/images/productos-img/125/carousel3.jpg"),
      require("./assets/images/productos-img/125/carousel4.jpg"),
      require("./assets/images/productos-img/125/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-yLQV-Vfm0Y"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 126,
    item: [105, 106, 107],
    name: "SUPER CARRERA 4",
    image: require("./assets/images/productos-img/126/principal.jpg"),
    size: "7x11",
    age: "6-13 años",
    descripcion:
      "Con 25 metros de carrera continua. Motor consumo: 18 amperes.",
    filtro: ["carreras", "grande"],
    carouselImages: [
      require("./assets/images/productos-img/126/principal.jpg"),
      require("./assets/images/productos-img/126/carousel2.jpg"),
      require("./assets/images/productos-img/126/carousel3.jpg"),
      require("./assets/images/productos-img/126/carousel4.jpg"),
      require("./assets/images/productos-img/126/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/JpQzgBQ6dBE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },

  // ACUATICOS
  {
    id: 200,
    item: 6,
    name: "INFANTIL AL BORDE",
    image: require("./assets/images/productos-img/200/principal.jpg"),
    size: "2,50x4x2",
    age: "2-7 años",
    descripcion: "Con tobogán y punchi.",
    filtro: ["acuaticos", "chico", "2-7 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/200/principal.jpg"),
      require("./assets/images/productos-img/200/carousel2.jpg"),
      require("./assets/images/productos-img/200/carousel3.jpg"),
      require("./assets/images/productos-img/200/carousel4.jpg"),
      require("./assets/images/productos-img/200/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/GygRjFt48Bw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 201,
    item: 6,
    name: "INFANTIL CON PILETA",
    image: require("./assets/images/productos-img/201/principal.jpg"),
    size: "2,50x6x2",
    age: "2-7 años",
    descripcion: "Con tobogán y punchi.",
    filtro: ["acuaticos", "chico", "2-7 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/201/principal.jpg"),
      require("./assets/images/productos-img/201/carousel2.jpg"),
      require("./assets/images/productos-img/201/carousel3.jpg"),
      require("./assets/images/productos-img/201/carousel4.jpg"),
      require("./assets/images/productos-img/201/carousel5.jpg"),
    ],
  },
  {
    id: 202,
    item: 7,
    name: "MAGICO AL BORDE",
    image: require("./assets/images/productos-img/202/principal.jpg"),
    size: "3x5x2,30",
    age: "2-7 años",
    descripcion: "Con tobogán y punchis.",
    filtro: ["acuaticos", "mediano", "2-7 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/202/principal.jpg"),
      require("./assets/images/productos-img/202/carousel2.jpg"),
      require("./assets/images/productos-img/202/carousel3.jpg"),
      require("./assets/images/productos-img/202/carousel4.jpg"),
      require("./assets/images/productos-img/202/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/w6mY6dbJ6uA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 203,
    item: 7,
    name: "MAGICO CON PILETA",
    image: require("./assets/images/productos-img/203/principal.jpg"),
    size: "3x7x2,30",
    age: "2-7 años",
    descripcion: "Con tobogán y punchis.",
    filtro: ["acuaticos", "mediano", "2-7 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/203/principal.jpg"),
      require("./assets/images/productos-img/203/carousel2.jpg"),
      require("./assets/images/productos-img/203/carousel3.jpg"),
      require("./assets/images/productos-img/203/carousel4.jpg"),
      require("./assets/images/productos-img/203/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1RXmbcOPUQk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 204,
    item: 22,
    name: "DINO AL BORDE",
    image: require("./assets/images/productos-img/204/principal.jpg"),
    size: "4x4x3,50",
    age: "2-7 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: ["acuaticos", "mediano", "2-7 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/204/principal.jpg"),
      require("./assets/images/productos-img/204/carousel2.jpg"),
      require("./assets/images/productos-img/204/carousel3.jpg"),
      require("./assets/images/productos-img/204/carousel4.jpg"),
      require("./assets/images/productos-img/204/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/EJIr4YP2SE4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 205,
    item: [22, 201],
    name: "DINO CON PILETA",
    image: require("./assets/images/productos-img/205/principal.jpg"),
    size: "4x7x3,50",
    age: "2-7 años",
    descripcion: "Área para saltar. Trepadora y tobogán.",
    filtro: ["acuaticos", "mediano", "2-7 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/205/principal.jpg"),
      require("./assets/images/productos-img/205/carousel2.jpg"),
      require("./assets/images/productos-img/205/carousel3.jpg"),
      require("./assets/images/productos-img/205/carousel4.jpg"),
      require("./assets/images/productos-img/205/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/sUrkTDwyqso"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 206,
    item: 32,
    name: "MURO 1 AL BORDE",
    image: require("./assets/images/productos-img/206/principal.jpg"),
    size: "3x6x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/206/principal.jpg"),
      require("./assets/images/productos-img/206/carousel2.jpg"),
      require("./assets/images/productos-img/206/carousel3.jpg"),
      require("./assets/images/productos-img/206/carousel4.jpg"),
      require("./assets/images/productos-img/206/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/h4hPKgZ3FWY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 207,
    item: 32,
    name: "MURO 1 CON PILETA",
    image: require("./assets/images/productos-img/207/principal.jpg"),
    size: "3x8x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/207/principal.jpg"),
      require("./assets/images/productos-img/207/carousel2.jpg"),
      require("./assets/images/productos-img/207/carousel3.jpg"),
      require("./assets/images/productos-img/207/carousel4.jpg"),
      require("./assets/images/productos-img/207/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZE1M4HklJ_8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 208,
    item: 33,
    name: "MURO 2 AL BORDE",
    image: require("./assets/images/productos-img/208/principal.jpg"),
    size: "4x6x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/208/principal.jpg"),
      require("./assets/images/productos-img/208/carousel2.jpg"),
      require("./assets/images/productos-img/208/carousel3.jpg"),
      require("./assets/images/productos-img/208/carousel4.jpg"),
      require("./assets/images/productos-img/208/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/0C_aRTMxfPQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 209,
    item: 33,
    name: "MURO 2 CON PILETA",
    image: require("./assets/images/productos-img/209/principal.jpg"),
    size: "4x9x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/209/principal.jpg"),
      require("./assets/images/productos-img/209/carousel2.jpg"),
      require("./assets/images/productos-img/209/carousel3.jpg"),
      require("./assets/images/productos-img/209/carousel4.jpg"),
      require("./assets/images/productos-img/209/carousel5.jpg"),
    ],
  },
  {
    id: 210,
    item: 26,
    name: "TREPADORA AL BORDE",
    image: require("./assets/images/productos-img/210/principal.jpg"),
    size: "3x7,50x2,70",
    age: "7-13 años",
    descripcion: "Con área para saltar. Trepadora y tobogán.",
    filtro: ["acuaticos", "mediano", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/210/principal.jpg"),
      require("./assets/images/productos-img/210/carousel2.jpg"),
      require("./assets/images/productos-img/210/carousel3.jpg"),
      require("./assets/images/productos-img/210/carousel4.jpg"),
      require("./assets/images/productos-img/210/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/IAgcZGI166w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 211,
    item: 26,
    name: "TREPADORA CON PILETA",
    image: require("./assets/images/productos-img/211/principal.jpg"),
    size: "3x10x2,70",
    age: "7-13 años",
    descripcion: "Con área para saltar. Trepadora y tobogán.",
    filtro: ["acuaticos", "mediano", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/211/principal.jpg"),
      require("./assets/images/productos-img/211/carousel2.jpg"),
      require("./assets/images/productos-img/211/carousel3.jpg"),
      require("./assets/images/productos-img/211/carousel4.jpg"),
      require("./assets/images/productos-img/211/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dlAgo5Hq-Jc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 212,
    item: 34,
    name: "SUPER ESCALADOR AL BORDE",
    image: require("./assets/images/productos-img/212/principal.jpg"),
    size: "6x5x5",
    age: "> 7 años",
    descripcion: "Con doble trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/212/principal.jpg"),
      require("./assets/images/productos-img/212/carousel2.jpg"),
      require("./assets/images/productos-img/212/carousel3.jpg"),
      require("./assets/images/productos-img/212/carousel4.jpg"),
      require("./assets/images/productos-img/212/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xizE0c902s0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 213,
    item: 34,
    name: "SUPER ESCALADOR CON PILETA",
    image: require("./assets/images/productos-img/213/principal.jpg"),
    size: "10x5x5",
    age: "> 7 años",
    descripcion: "Con doble trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/213/principal.jpg"),
      require("./assets/images/productos-img/213/carousel2.jpg"),
      require("./assets/images/productos-img/213/carousel3.jpg"),
      require("./assets/images/productos-img/213/carousel4.jpg"),
      require("./assets/images/productos-img/213/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/vDMfLc2NnRE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 214,
    item: [33, 103],
    name: "CARRERA AL BORDE",
    image: require("./assets/images/productos-img/214/principal.jpg"),
    size: "12x4x4,50",
    age: "7-13 años",
    descripcion: "",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/214/principal.jpg"),
      require("./assets/images/productos-img/214/carousel2.jpg"),
      require("./assets/images/productos-img/214/carousel3.jpg"),
      require("./assets/images/productos-img/214/carousel4.jpg"),
      require("./assets/images/productos-img/214/carousel5.jpg"),
    ],
  },
  {
    id: 215,
    item: [33, 103],
    name: "CARRERA CON PILETA",
    image: require("./assets/images/productos-img/215/principal.jpg"),
    size: "15x4x4,50",
    age: "7-13 años",
    descripcion: "",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/215/principal.jpg"),
      require("./assets/images/productos-img/215/carousel2.jpg"),
      require("./assets/images/productos-img/215/carousel3.jpg"),
    ],
  },
  {
    id: 216,
    item: 37,
    name: "MUNDO MARINO AL BORDE",
    image: require("./assets/images/productos-img/216/principal.jpg"),
    size: "5x5,50x4",
    age: "> 7 años",
    descripcion: "Con muro y tobogán.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/216/principal.jpg"),
      require("./assets/images/productos-img/216/carousel2.jpg"),
      require("./assets/images/productos-img/216/carousel3.jpg"),
      require("./assets/images/productos-img/216/carousel4.jpg"),
      require("./assets/images/productos-img/216/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/NZw1IFgnIr0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 217,
    item: 37,
    name: "MUNDO MARINO CON PILETA",
    image: require("./assets/images/productos-img/217/principal.jpg"),
    size: "5x8,50x4",
    age: "> 7 años",
    descripcion: "Con muro y tobogán.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/217/principal.jpg"),
      require("./assets/images/productos-img/217/carousel2.jpg"),
      require("./assets/images/productos-img/217/carousel3.jpg"),
      require("./assets/images/productos-img/217/carousel4.jpg"),
      require("./assets/images/productos-img/217/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/LB60OhexS48"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 218,
    item: 35,
    name: "MULTICOLOR AL BORDE",
    image: require("./assets/images/productos-img/218/principal.jpg"),
    size: "3x5x3,50",
    age: "7-13 años",
    descripcion: "Trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/218/principal.jpg"),
      require("./assets/images/productos-img/218/carousel2.jpg"),
      require("./assets/images/productos-img/218/carousel3.jpg"),
      require("./assets/images/productos-img/218/carousel4.jpg"),
      require("./assets/images/productos-img/218/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cXtUWpfFZZI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 219,
    item: 35,
    name: "MULTICOLOR CON PILETA",
    image: require("./assets/images/productos-img/219/principal.jpg"),
    size: "3x7x3,50",
    age: "7-13 años",
    descripcion: "Trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/219/principal.jpg"),
      require("./assets/images/productos-img/219/carousel2.jpg"),
      require("./assets/images/productos-img/219/carousel3.jpg"),
      require("./assets/images/productos-img/219/carousel4.jpg"),
      require("./assets/images/productos-img/219/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/Twfc08bKn0o"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 220,
    item: 36,
    name: "SURF AL BORDE",
    image: require("./assets/images/productos-img/220/principal.jpg"),
    size: "6,20x4x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/220/principal.jpg"),
      require("./assets/images/productos-img/220/carousel2.jpg"),
      require("./assets/images/productos-img/220/carousel3.jpg"),
      require("./assets/images/productos-img/220/carousel4.jpg"),
      require("./assets/images/productos-img/220/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/zwyXJ9N7cgQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 221,
    item: 36,
    name: "SURF CON PILETA",
    image: require("./assets/images/productos-img/221/principal.jpg"),
    size: "9x4x4,50",
    age: "> 7 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/221/principal.jpg"),
      require("./assets/images/productos-img/221/carousel2.jpg"),
      require("./assets/images/productos-img/221/carousel3.jpg"),
      require("./assets/images/productos-img/221/carousel4.jpg"),
      require("./assets/images/productos-img/221/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/YDoiSMA76zM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 222,
    item: [202, 38],
    name: "AVENTURA 1 CON PILETA",
    image: require("./assets/images/productos-img/222/principal.jpg"),
    size: "6x9x4",
    age: "7-13 años",
    descripcion: "Con tobogán curvo, y con splash.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/222/principal.jpg"),
      require("./assets/images/productos-img/222/carousel2.jpg"),
      require("./assets/images/productos-img/222/carousel3.jpg"),
      require("./assets/images/productos-img/222/carousel4.jpg"),
      require("./assets/images/productos-img/222/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rkKKyLCWMdk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 223,
    item: 39,
    name: "AVENTURA 2 AL BORDE",
    image: require("./assets/images/productos-img/223/principal.jpg"),
    size: "6x5x4,50",
    age: "> 7 años",
    descripcion: "Con tobogán curvo.",
    filtro: ["acuaticos", "grande", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/223/principal.jpg"),
      require("./assets/images/productos-img/223/carousel2.jpg"),
      require("./assets/images/productos-img/223/carousel3.jpg"),
      require("./assets/images/productos-img/223/carousel4.jpg"),
      require("./assets/images/productos-img/223/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/JoZrjGZnw28"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 224,
    item: 39,
    name: "AVENTURA 2 CON PILETA",
    image: require("./assets/images/productos-img/224/principal.jpg"),
    size: "6x9x4,50",
    age: "> 7 años",
    descripcion: "Con tobogán curvo.",
    filtro: ["acuaticos", "grande", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/224/principal.jpg"),
      require("./assets/images/productos-img/224/carousel2.jpg"),
      require("./assets/images/productos-img/224/carousel3.jpg"),
      require("./assets/images/productos-img/224/carousel4.jpg"),
      require("./assets/images/productos-img/224/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/iz5liDjbxCg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 225,
    item: 202,
    name: "SPLASH 1 AL BORDE",
    image: require("./assets/images/productos-img/225/principal.jpg"),
    size: "6,50x1,50",
    age: "7-13 años",
    descripcion: "Deslizador de agua inflable, carril simple.",
    filtro: ["acuaticos", "mediano", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/225/principal.jpg"),
      require("./assets/images/productos-img/225/carousel2.jpg"),
      require("./assets/images/productos-img/225/carousel3.jpg"),
      require("./assets/images/productos-img/225/carousel4.jpg"),
      require("./assets/images/productos-img/225/carousel5.jpg"),
    ],
  },
  {
    id: 226,
    item: [202, 201],
    name: "SPLASH 1 CON PILETA",
    image: require("./assets/images/productos-img/226/principal.jpg"),
    size: "8x1,50",
    age: "7-13 años",
    descripcion: "Deslizador de agua inflable, carril simple.",
    filtro: ["acuaticos", "mediano", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/226/principal.jpg"),
      require("./assets/images/productos-img/226/carousel2.jpg"),
      require("./assets/images/productos-img/226/carousel3.jpg"),
      require("./assets/images/productos-img/226/carousel4.jpg"),
      require("./assets/images/productos-img/226/carousel5.jpg"),
    ],
  },
  {
    id: 227,
    item: 203,
    name: "SPLASH DOBLE AL BORDE",
    image: require("./assets/images/productos-img/227/principal.jpg"),
    size: "6,50x2,50",
    age: "> 7 años",
    descripcion: "Deslizador de agua inflable, doble carril.",
    filtro: ["acuaticos", "mediano", "7-13 años", "al borde"],
    carouselImages: [
      require("./assets/images/productos-img/227/principal.jpg"),
      require("./assets/images/productos-img/227/carousel2.jpg"),
      require("./assets/images/productos-img/227/carousel3.jpg"),
      require("./assets/images/productos-img/227/carousel4.jpg"),
      require("./assets/images/productos-img/227/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/JZJNcfL0Pws"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 228,
    item: 203,
    name: "SPLASH DOBLE CON PILETA",
    image: require("./assets/images/productos-img/228/principal.jpg"),
    size: "9x2,50",
    age: "> 7 años",
    descripcion: "Deslizador de agua inflable, doble carril.",
    filtro: ["acuaticos", "mediano", "7-13 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/228/principal.jpg"),
      require("./assets/images/productos-img/228/carousel2.jpg"),
      require("./assets/images/productos-img/228/carousel3.jpg"),
      require("./assets/images/productos-img/228/carousel4.jpg"),
      require("./assets/images/productos-img/228/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/fKJK5vdGGx4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 229,
    item: [43, 204],
    name: "SAFARI CON TORTUGA PELOTERO",
    image: require("./assets/images/productos-img/49/principal.jpg"),
    size: "4x5,50x5",
    age: "2-7 años",
    descripcion: "Área para saltar, Trepadora y tobogán.",
    filtro: ["acuaticos", "mediano", "2-7 años", "con pileta"],
    carouselImages: [
      require("./assets/images/productos-img/49/principal.jpg"),
      require("./assets/images/productos-img/49/carousel3.jpg"),
      require("./assets/images/productos-img/49/carousel4.jpg"),
      require("./assets/images/productos-img/49/carousel5.jpg"),
    ],
  },
  // ----------------- KERMESSE -----------------------
  {
    id: 300,
    item: 301,
    name: "TIRO AL BLANCO",
    image: require("./assets/images/productos-img/300/principal.jpg"),
    size: "2x1",
    age: "> 5 años",
    descripcion:
      "Hay que embocar el dardo de abrojo en la lona, lo más cercano al centro posible, sumas más puntos.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/300/principal.jpg"),
      require("./assets/images/productos-img/300/carousel2.jpg"),
      require("./assets/images/productos-img/300/carousel3.jpg"),
      require("./assets/images/productos-img/300/carousel4.jpg"),
      require("./assets/images/productos-img/300/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xM4TNiwwiBQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 301,
    item: 302,
    name: "CARRERA FLOTADORA ",
    image: require("./assets/images/productos-img/301/principal.jpg"),
    size: "3x1,50",
    age: "> 5 años",
    descripcion:
      "Tenés que pasar una pelotita entre los conos que tiene una abertura que larga aire, lo cual hace que ésta flote, y avanzar hasta el extremo del juego hasta embocar la pelotita en el aro.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/301/principal.jpg"),
      require("./assets/images/productos-img/301/carousel2.jpg"),
      require("./assets/images/productos-img/301/carousel3.jpg"),
      require("./assets/images/productos-img/301/carousel4.jpg"),
      require("./assets/images/productos-img/301/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/VPw7BZMmfz0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 302,
    item: 303,
    name: "BASQUET",
    image: require("./assets/images/productos-img/302/principal.jpg"),
    size: "2x2",
    age: "> 5 años",
    descripcion:
      "Son dos aros. Competencia entre dos participantes, quien más rápido emboque la pelota gana.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/302/principal.jpg"),
      require("./assets/images/productos-img/302/carousel2.jpg"),
      require("./assets/images/productos-img/302/carousel3.jpg"),
      require("./assets/images/productos-img/302/carousel4.jpg"),
      require("./assets/images/productos-img/302/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xsFkC9-kY5U"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 303,
    item: 304,
    name: "PELOTITAS FLOTADORAS ",
    image: require("./assets/images/productos-img/303/principal.jpg"),
    size: "2x2",
    age: "> 5 años",
    descripcion:
      "Se ponen 5 pelotitas sobre el inflable que van a estar flotando por donde sale aire. El objetivo es derribarlas con una pelotita.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/303/principal.jpg"),
      require("./assets/images/productos-img/303/carousel2.jpg"),
      require("./assets/images/productos-img/303/carousel3.jpg"),
      require("./assets/images/productos-img/303/carousel4.jpg"),
      require("./assets/images/productos-img/303/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/kYSdKRawfBg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 304,
    item: 305,
    name: "SAPO",
    image: require("./assets/images/productos-img/304/principal.jpg"),
    size: "2x2",
    age: "> 5 años",
    descripcion:
      "Similar al sapo tradicional, pero en versión inflable. Hay que embocar la pelotita en los agujeros del inflable.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/304/principal.jpg"),
      require("./assets/images/productos-img/304/carousel2.jpg"),
      require("./assets/images/productos-img/304/carousel3.jpg"),
      require("./assets/images/productos-img/304/carousel4.jpg"),
      require("./assets/images/productos-img/304/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/iIT7sXfcK1E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 305,
    item: 306,
    name: "MONITO",
    image: require("./assets/images/productos-img/305/principal.jpg"),
    size: "2x2",
    age: "> 5 años",
    descripcion:
      "Embocar la pelotita en los agujeros del panel frontal del inflable.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/305/principal.jpg"),
      require("./assets/images/productos-img/305/carousel2.jpg"),
      require("./assets/images/productos-img/305/carousel3.jpg"),
      require("./assets/images/productos-img/305/carousel4.jpg"),
      require("./assets/images/productos-img/305/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/6PPHMHg5jAo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 306,
    item: 307,
    name: "EMBOQUE",
    image: require("./assets/images/productos-img/306/principal.jpg"),
    size: "2x2",
    age: "> 5 años",
    descripcion:
      "Embocar en los agujeros de la lona frontal del inflable. Embocá, sumá puntos y ganá!",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/306/principal.jpg"),
      require("./assets/images/productos-img/306/carousel2.jpg"),
      require("./assets/images/productos-img/306/carousel3.jpg"),
      require("./assets/images/productos-img/306/carousel4.jpg"),
      require("./assets/images/productos-img/306/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/lTahfGDBslE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 307,
    item: 308,
    name: "MULTIJUEGOS",
    image: require("./assets/images/productos-img/307/principal.jpg"),
    size: "4x3",
    age: "> 5 años",
    descripcion:
      "Son 4 juegos en 1: aro de básquet, embocar corchos en la lona, apoyar fichas en los círculos de la lona inferior y embocar aros en los conos.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/307/principal.jpg"),
      require("./assets/images/productos-img/307/carousel2.jpg"),
      require("./assets/images/productos-img/307/carousel3.jpg"),
      require("./assets/images/productos-img/307/carousel4.jpg"),
      require("./assets/images/productos-img/307/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cZ3NYGMvPMo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 308,
    item: 309,
    name: "KERMESSE COMPLETA",
    image: require("./assets/images/productos-img/308/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion:
      "Kermesse completa con animación, 3 horas, 3 animadores, traslado, ambientación, 12 puestos y kiosko.",
    filtro: ["kermesse"],
    carouselImages: [
      require("./assets/images/productos-img/308/principal.jpg"),
      require("./assets/images/productos-img/308/carousel2.jpg"),
      require("./assets/images/productos-img/308/carousel3.jpg"),
      require("./assets/images/productos-img/308/carousel4.jpg"),
      require("./assets/images/productos-img/308/carousel5.jpg"),
    ],
    consultar: true,
  },

  // Mini Livings
  {
    id: 400,
    item: 401,
    name: "COLORIDA 1 ROJO",
    image: require("./assets/images/productos-img/400/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa roja 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/400/principal.jpg"),
      require("./assets/images/productos-img/400/carousel2.jpg"),
      require("./assets/images/productos-img/400/carousel3.jpg"),
      require("./assets/images/productos-img/400/carousel4.jpg"),
      require("./assets/images/productos-img/400/carousel5.jpg"),
    ],
  },
  {
    id: 401,
    item: 402,
    name: "COLORIDA 1 VIOLETA",
    image: require("./assets/images/productos-img/401/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa violeta 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/401/principal.jpg"),
      require("./assets/images/productos-img/401/carousel2.jpg"),
      require("./assets/images/productos-img/401/carousel3.jpg"),
      require("./assets/images/productos-img/401/carousel4.jpg"),
      require("./assets/images/productos-img/401/carousel5.jpg"),
    ],
  },
  {
    id: 402,
    item: [401, 402],
    name: "COLORIDA 1 PLUS",
    image: require("./assets/images/productos-img/402/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 mini puff y 2 mesas roja y violeta 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/402/principal.jpg"),
      require("./assets/images/productos-img/402/carousel2.jpg"),
      require("./assets/images/productos-img/402/carousel3.jpg"),
      require("./assets/images/productos-img/402/carousel4.jpg"),
      require("./assets/images/productos-img/402/carousel5.jpg"),
    ],
  },
  {
    id: 403,
    item: 403,
    name: "COLORIDA 2 ROJO",
    image: require("./assets/images/productos-img/403/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa roja 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/403/principal.jpg"),
      require("./assets/images/productos-img/403/carousel2.jpg"),
      require("./assets/images/productos-img/403/carousel3.jpg"),
      require("./assets/images/productos-img/403/carousel4.jpg"),
      require("./assets/images/productos-img/403/carousel5.jpg"),
    ],
  },
  {
    id: 404,
    item: 404,
    name: "COLORIDA 2 VERDE",
    image: require("./assets/images/productos-img/404/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa verde 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/404/principal.jpg"),
      require("./assets/images/productos-img/404/carousel2.jpg"),
      require("./assets/images/productos-img/404/carousel3.jpg"),
      require("./assets/images/productos-img/404/carousel4.jpg"),
      require("./assets/images/productos-img/404/carousel5.jpg"),
    ],
  },
  {
    id: 405,
    item: [403, 404],
    name: "COLORIDA 2 PLUS",
    image: require("./assets/images/productos-img/405/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 mini puff y 2 mesas roja y verde 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/405/principal.jpg"),
      require("./assets/images/productos-img/405/carousel2.jpg"),
      require("./assets/images/productos-img/405/carousel3.jpg"),
      require("./assets/images/productos-img/405/carousel4.jpg"),
      require("./assets/images/productos-img/405/carousel5.jpg"),
    ],
  },
  {
    id: 406,
    item: 405,
    name: "IRREGULAR AZUL",
    image: require("./assets/images/productos-img/406/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa azul 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/406/principal.jpg"),
      require("./assets/images/productos-img/406/carousel2.jpg"),
      require("./assets/images/productos-img/406/carousel3.jpg"),
      require("./assets/images/productos-img/406/carousel4.jpg"),
      require("./assets/images/productos-img/406/carousel5.jpg"),
    ],
  },
  {
    id: 407,
    item: 406,
    name: "IRREGULAR ROSA",
    image: require("./assets/images/productos-img/407/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa rosa 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/407/principal.jpg"),
      require("./assets/images/productos-img/407/carousel2.jpg"),
      require("./assets/images/productos-img/407/carousel3.jpg"),
    ],
  },
  {
    id: 408,
    item: [405, 406],
    name: "IRREGULAR PLUS",
    image: require("./assets/images/productos-img/408/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 mini puff y 2 mesas azul y rosa 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/408/principal.jpg"),
      require("./assets/images/productos-img/408/carousel2.jpg"),
      require("./assets/images/productos-img/408/carousel3.jpg"),
      require("./assets/images/productos-img/408/carousel4.jpg"),
      require("./assets/images/productos-img/408/carousel5.jpg"),
    ],
  },
  {
    id: 409,
    item: 407,
    name: "MINILIVING BLANCO (10)",
    image: require("./assets/images/productos-img/409/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 mini puff y 1 mesa blanca 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/409/principal.jpg"),
      require("./assets/images/productos-img/409/carousel2.jpg"),
      require("./assets/images/productos-img/409/carousel3.jpg"),
      require("./assets/images/productos-img/409/carousel4.jpg"),
      require("./assets/images/productos-img/409/carousel5.jpg"),
    ],
  },
  {
    id: 410,
    item: 408,
    name: "MINILIVING BLANCO (20)",
    image: require("./assets/images/productos-img/410/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 mini puff y 2 mesas blancas 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/410/principal.jpg"),
      require("./assets/images/productos-img/410/carousel2.jpg"),
      require("./assets/images/productos-img/410/carousel3.jpg"),
      require("./assets/images/productos-img/410/carousel4.jpg"),
      require("./assets/images/productos-img/410/carousel5.jpg"),
    ],
  },
  // {
  //   id: 411,
  //   item: 409,
  //   name: "MINILIVING BLANCO (30)",
  //   image: require("./assets/images/productos-img/blank/principal.jpg"),
  //   size: "-",
  //   age: "-",
  //   descripcion: "30 mini puff y 3 mesas blancas 1,20 x 0,70.",
  //   filtro: ["livings", "miniliving"],
  //   carouselImages: [
  //     require("./assets/images/productos-img/blank/principal.jpg"),
  //     require("./assets/images/productos-img/blank/carousel2.jpg"),
  //     require("./assets/images/productos-img/blank/carousel3.jpg"),
  //     require("./assets/images/productos-img/blank/carousel4.jpg"),
  //     require("./assets/images/productos-img/blank/carousel5.jpg"),
  //   ],
  // },
  {
    id: 413,
    item: [408, 458],
    name: "MINILIVING BLANCO (20) + GAZEBO",
    image: require("./assets/images/productos-img/413/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 mini puff, 2 mesas blancas 1,20 x 0,70 y 1 gazebo blanco.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/413/principal.jpg"),
      require("./assets/images/productos-img/413/carousel2.jpg"),
      require("./assets/images/productos-img/413/carousel3.jpg"),
      require("./assets/images/productos-img/413/carousel4.jpg"),
      require("./assets/images/productos-img/413/carousel5.jpg"),
    ],
  },
  {
    id: 414,
    item: 410,
    name: "MESAS Y SILLITAS (para 10)",
    image: require("./assets/images/productos-img/414/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "10 sillitas, 2 mesas blancas 1,20 x 0,60.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/414/principal.jpg"),
      require("./assets/images/productos-img/414/carousel2.jpg"),
      require("./assets/images/productos-img/414/carousel3.jpg"),
      require("./assets/images/productos-img/414/carousel4.jpg"),
      require("./assets/images/productos-img/414/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 415,
    item: 411,
    name: "MESAS Y SILLITAS (para 20)",
    image: require("./assets/images/productos-img/415/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "20 sillitas, 4 mesas blancas 1,20 x 0,60.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      require("./assets/images/productos-img/415/principal.jpg"),
      require("./assets/images/productos-img/415/carousel2.jpg"),
      require("./assets/images/productos-img/415/carousel3.jpg"),
      require("./assets/images/productos-img/415/carousel4.jpg"),
      require("./assets/images/productos-img/415/carousel5.jpg"),
    ],
    consultar: true,
  },

  // Livings
  {
    id: 450,
    item: 451,
    name: "LIVING SIMPLE (10)",
    image: require("./assets/images/productos-img/450/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "10 puff cuadrados y redondos + 1 mesa puente 1 x 0,50. Si querés modificar la mesa por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/450/principal.jpg"),
      require("./assets/images/productos-img/450/carousel2.jpg"),
      require("./assets/images/productos-img/450/carousel3.jpg"),
      require("./assets/images/productos-img/450/carousel4.jpg"),
      require("./assets/images/productos-img/450/carousel5.jpg"),
    ],
  },

  {
    id: 451,
    item: 452,
    name: "LIVING CON BUTACON (10)",
    image: require("./assets/images/productos-img/451/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "Living para 10 personas con butacón, puff cuadrados y redondos + 1 mesa puente 1 x 0,50. Si querés modificar la mesa por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/451/principal.jpg"),
      require("./assets/images/productos-img/451/carousel2.jpg"),
      require("./assets/images/productos-img/451/carousel3.jpg"),
      require("./assets/images/productos-img/451/carousel4.jpg"),
    ],
  },

  {
    id: 452,
    item: 453,
    name: "LIVING CHOCOLATE (10)",
    image: require("./assets/images/productos-img/452/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "10 puff redondos color chocolate + 1 mesa puente 1 x 0,50. Si querés modificar la mesa por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/452/principal.jpg"),
      require("./assets/images/productos-img/452/carousel2.jpg"),
      require("./assets/images/productos-img/452/carousel3.jpg"),
      require("./assets/images/productos-img/452/carousel4.jpg"),
      require("./assets/images/productos-img/452/carousel5.jpg"),
    ],
  },

  {
    id: 453,
    item: 454,
    name: "LIVING SIMPLE (20)",
    image: require("./assets/images/productos-img/453/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "20 puff cuadrados y redondos + 2 mesas puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/453/principal.jpg"),
      require("./assets/images/productos-img/453/carousel2.jpg"),
      require("./assets/images/productos-img/453/carousel3.jpg"),
      require("./assets/images/productos-img/453/carousel4.jpg"),
      require("./assets/images/productos-img/453/carousel5.jpg"),
    ],
  },

  {
    id: 454,
    item: 455,
    name: "LIVING CON BUTACON (20)",
    image: require("./assets/images/productos-img/454/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "Living para 20 personas con butacón, puff cuadrados y redondos + 2 mesas puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/454/principal.jpg"),
      require("./assets/images/productos-img/454/carousel2.jpg"),
      require("./assets/images/productos-img/454/carousel3.jpg"),
      require("./assets/images/productos-img/454/carousel4.jpg"),
      require("./assets/images/productos-img/454/carousel5.jpg"),
    ],
  },

  {
    id: 455,
    item: 456,
    name: "LIVING SIMPLE (30)",
    image: require("./assets/images/productos-img/455/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "30 puff cuadrados y redondos + 3 mesas puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/455/principal.jpg"),
      require("./assets/images/productos-img/455/carousel2.jpg"),
      require("./assets/images/productos-img/455/carousel3.jpg"),
      require("./assets/images/productos-img/455/carousel4.jpg"),
      require("./assets/images/productos-img/455/carousel5.jpg"),
    ],
  },

  {
    id: 456,
    item: 455,
    name: "LIVING CON BUTACON (30)",
    image: require("./assets/images/productos-img/456/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "Living para 30 personas con butacón, puff cuadrados y redondos + 3 mesas puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/456/principal.jpg"),
      require("./assets/images/productos-img/456/carousel2.jpg"),
      require("./assets/images/productos-img/456/carousel3.jpg"),
    ],
  },

  {
    id: 457,
    item: 457,
    name: "GAZEBO BLANCO",
    image: require("./assets/images/productos-img/457/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/457/principal.jpg"),
      require("./assets/images/productos-img/457/carousel2.jpg"),
      require("./assets/images/productos-img/457/carousel3.jpg"),
      require("./assets/images/productos-img/457/carousel4.jpg"),
      require("./assets/images/productos-img/457/carousel5.jpg"),
    ],
  },

  {
    id: 458,
    item: 458,
    name: "GAZEBO BLANCO",
    image: require("./assets/images/productos-img/457/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/457/principal.jpg"),
      require("./assets/images/productos-img/457/carousel2.jpg"),
      require("./assets/images/productos-img/457/carousel3.jpg"),
      require("./assets/images/productos-img/457/carousel4.jpg"),
      require("./assets/images/productos-img/457/carousel5.jpg"),
    ],
  },

  {
    id: 459,
    item: 459,
    name: "GAZEBO BLANCO",
    image: require("./assets/images/productos-img/457/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/457/principal.jpg"),
      require("./assets/images/productos-img/457/carousel2.jpg"),
      require("./assets/images/productos-img/457/carousel3.jpg"),
      require("./assets/images/productos-img/457/carousel4.jpg"),
      require("./assets/images/productos-img/457/carousel5.jpg"),
    ],
  },

  {
    id: 460,
    item: 460,
    name: "GAZEBO AZUL",
    image: require("./assets/images/productos-img/460/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/460/principal.jpg"),
      require("./assets/images/productos-img/460/carousel2.jpg"),
      require("./assets/images/productos-img/460/carousel3.jpg"),
      require("./assets/images/productos-img/460/carousel4.jpg"),
      require("./assets/images/productos-img/460/carousel5.jpg"),
    ],
  },

  {
    id: 461,
    item: 461,
    name: "GAZEBO AZUL",
    image: require("./assets/images/productos-img/460/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/460/principal.jpg"),
      require("./assets/images/productos-img/460/carousel2.jpg"),
      require("./assets/images/productos-img/460/carousel3.jpg"),
      require("./assets/images/productos-img/460/carousel4.jpg"),
      require("./assets/images/productos-img/460/carousel5.jpg"),
    ],
  },

  {
    id: 462,
    item: 462,
    name: "GAZEBO AZUL",
    image: require("./assets/images/productos-img/460/principal.jpg"),
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/460/principal.jpg"),
      require("./assets/images/productos-img/460/carousel2.jpg"),
      require("./assets/images/productos-img/460/carousel3.jpg"),
      require("./assets/images/productos-img/460/carousel4.jpg"),
      require("./assets/images/productos-img/460/carousel5.jpg"),
    ],
  },
  {
    id: 463,
    item: 463,
    name: "LIVING BLANCO/CHOCOLATE (10)",
    image: require("./assets/images/productos-img/463/principal.jpg"),
    size: "",
    age: "-",
    descripcion:
      "10 puff redondos color chocolate y blanco + 1 mesa puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/463/principal.jpg"),
      require("./assets/images/productos-img/463/carousel2.jpg"),
      require("./assets/images/productos-img/463/carousel3.jpg"),
      require("./assets/images/productos-img/463/carousel4.jpg"),
      require("./assets/images/productos-img/463/carousel5.jpg"),
    ],
  },
  {
    id: 464,
    item: 453,
    name: "LIVING BLANCO/CHOCOLATE (20)",
    image: require("./assets/images/productos-img/464/principal.jpg"),
    size: "",
    age: "-",
    descripcion:
      "20 puff redondos color chocolate y blanco + 1 mesa puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/464/principal.jpg"),
      require("./assets/images/productos-img/464/carousel2.jpg"),
      require("./assets/images/productos-img/464/carousel3.jpg"),
      require("./assets/images/productos-img/464/carousel4.jpg"),
      require("./assets/images/productos-img/464/carousel5.jpg"),
    ],
  },
  {
    id: 465,
    item: [453, 463],
    name: "LIVING BLANCO/CHOCOLATE (30)",
    image: require("./assets/images/productos-img/465/principal.jpg"),
    size: "",
    age: "-",
    descripcion:
      "30 puff redondos color chocolate y blanco + 1 mesa puente 1 x 0,50. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/465/principal.jpg"),
      require("./assets/images/productos-img/465/carousel2.jpg"),
      require("./assets/images/productos-img/465/carousel3.jpg"),
      require("./assets/images/productos-img/465/carousel4.jpg"),
      require("./assets/images/productos-img/465/carousel5.jpg"),
    ],
  },
  {
    id: 467,
    item: 467,
    name: "LIVING + GAZEBO (20)",
    image: require("./assets/images/productos-img/467/principal.jpg"),
    size: "",
    age: "-",
    descripcion:
      "Living para 20 personas con 1 butacón, puff cuadrados + 2 mesas puente 1 x 0,50 + 1 Gazebo. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/467/principal.jpg"),
      require("./assets/images/productos-img/467/carousel2.jpg"),
      require("./assets/images/productos-img/467/carousel3.jpg"),
      require("./assets/images/productos-img/467/carousel4.jpg"),
      require("./assets/images/productos-img/467/carousel5.jpg"),
    ],
  },
  {
    id: 469,
    item: 469,
    name: "LIVING + 2 GAZEBOS (40)",
    image: require("./assets/images/productos-img/469/principal.jpg"),
    size: "",
    age: "-",
    descripcion:
      "Living para 40 personas con 2 butacones, puff cuadrados + 2 mesas puente 1 x 0,50 + 2 Gazebos. Si querés modificar las mesas por una mas grande (1,20 x 0,70) tiene un recargo de $2.000 por cada una (solicitar por WhatsApp la modificación).",
    filtro: ["livings", "living"],
    carouselImages: [
      require("./assets/images/productos-img/469/principal.jpg"),
      require("./assets/images/productos-img/469/carousel2.jpg"),
      require("./assets/images/productos-img/469/carousel3.jpg"),
      require("./assets/images/productos-img/469/carousel4.jpg"),
      require("./assets/images/productos-img/469/carousel5.jpg"),
    ],
  },
  // ----------------------- JUEGOS ------------------------------
  {
    id: 501,
    item: 502,
    name: "METEGOL",
    image: require("./assets/images/productos-img/501/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Incluye una pelotita.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/501/principal.jpg"),
      require("./assets/images/productos-img/501/carousel2.jpg"),
      require("./assets/images/productos-img/501/carousel3.jpg"),
      require("./assets/images/productos-img/501/carousel4.jpg"),
      require("./assets/images/productos-img/501/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 502,
    item: 503,
    name: "METEGOL",
    image: require("./assets/images/productos-img/501/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Incluye una pelotita.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/501/principal.jpg"),
      require("./assets/images/productos-img/501/carousel2.jpg"),
      require("./assets/images/productos-img/501/carousel3.jpg"),
      require("./assets/images/productos-img/501/carousel4.jpg"),
      require("./assets/images/productos-img/501/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 503,
    item: 504,
    name: "METEGOL",
    image: require("./assets/images/productos-img/501/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Incluye una pelotita.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/501/principal.jpg"),
      require("./assets/images/productos-img/501/carousel2.jpg"),
      require("./assets/images/productos-img/501/carousel3.jpg"),
      require("./assets/images/productos-img/501/carousel4.jpg"),
      require("./assets/images/productos-img/501/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 504,
    item: 505,
    name: "MESA DE TEJO",
    image: require("./assets/images/productos-img/504/principal.jpg"),
    size: "1,70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/504/principal.jpg"),
      require("./assets/images/productos-img/504/carousel2.jpg"),
      require("./assets/images/productos-img/504/carousel3.jpg"),
      require("./assets/images/productos-img/504/carousel4.jpg"),
      require("./assets/images/productos-img/504/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 505,
    item: 506,
    name: "MESA DE TEJO",
    image: require("./assets/images/productos-img/504/principal.jpg"),
    size: "1,70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/504/principal.jpg"),
      require("./assets/images/productos-img/504/carousel2.jpg"),
      require("./assets/images/productos-img/504/carousel3.jpg"),
      require("./assets/images/productos-img/504/carousel4.jpg"),
      require("./assets/images/productos-img/504/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 506,
    item: 507,
    name: "MESA DE TEJO",
    image: require("./assets/images/productos-img/504/principal.jpg"),
    size: "1,70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/504/principal.jpg"),
      require("./assets/images/productos-img/504/carousel2.jpg"),
      require("./assets/images/productos-img/504/carousel3.jpg"),
      require("./assets/images/productos-img/504/carousel4.jpg"),
      require("./assets/images/productos-img/504/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 507,
    item: 508,
    name: "MESA DE POOL MEDIANA",
    image: require("./assets/images/productos-img/507/principal.jpg"),
    size: "1,70 x 1 ",
    age: "> 5 años",
    descripcion:
      "Incluye 2 tacos, 1 bola blanca, 15 bolas de colores y 1 triángulo.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/507/principal.jpg"),
      require("./assets/images/productos-img/507/carousel2.jpg"),
      require("./assets/images/productos-img/507/carousel3.jpg"),
      require("./assets/images/productos-img/507/carousel4.jpg"),
      require("./assets/images/productos-img/507/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/GVJ0JOclc6A"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 508,
    item: 509,
    name: "POOL SEMI PROF.",
    image: require("./assets/images/productos-img/508/principal.jpg"),
    size: "1,85 x 1,10",
    age: "> 5 años",
    descripcion:
      "Incluye 2 tacos, 1 bola blanca, 15 bolas de colores y 1 triángulo.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/508/principal.jpg"),
      require("./assets/images/productos-img/508/carousel2.jpg"),
      require("./assets/images/productos-img/508/carousel3.jpg"),
      require("./assets/images/productos-img/508/carousel4.jpg"),
      require("./assets/images/productos-img/508/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/oj3nf9fIh-4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  // {
  //   id: 509,
  //   item: 510,
  //   name: "MESA DE PING PONG MEDIANA",
  //   image: require("./assets/images/productos-img/509/principal.jpg"),
  //   size: "2,40 x 1,20",
  //   age: "> 5 años",
  //   descripcion:
  //     "Mesa de ping pong mediana. Incluye 4 paletas, 2 pelotitas y 1 red.",
  //   filtro: ["juegos"],
  //   carouselImages: [
  //     require("./assets/images/productos-img/509/principal.jpg"),
  //     require("./assets/images/productos-img/509/carousel2.jpg"),
  //     require("./assets/images/productos-img/509/carousel3.jpg"),
  //     require("./assets/images/productos-img/509/carousel4.jpg"),
  //     require("./assets/images/productos-img/509/carousel5.jpg"),
  //   ],

  //   video: (
  //     <iframe
  //       className="carousel-image-grande"
  //       width="100%"
  //       height="100%"
  //       src="https://www.youtube.com/embed/25jQFLp-FaA"
  //       title="YouTube video player"
  //       frameBorder="0"
  //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //       allowFullScreen
  //     ></iframe>
  //   ),
  // },
  {
    id: 510,
    item: 511,
    name: "MESA DE PING PONG PROFESIONAL",
    image: require("./assets/images/productos-img/510/principal.jpg"),
    size: "2.74 x 1.52",
    age: "> 5 años",
    descripcion:
      "Mesa de ping pong profesional. Incluye 4 paletas, 2 pelotitas y 1 red.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/510/principal.jpg"),
      require("./assets/images/productos-img/510/carousel2.jpg"),
      require("./assets/images/productos-img/510/carousel3.jpg"),
      require("./assets/images/productos-img/510/carousel4.jpg"),
      require("./assets/images/productos-img/510/carousel5.jpg"),
    ],
  },
  {
    id: 511,
    item: 512,
    name: "JENGA MEDIANO",
    image: require("./assets/images/productos-img/511/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "45 cm de alto.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/511/principal.jpg"),
      require("./assets/images/productos-img/511/carousel2.jpg"),
      require("./assets/images/productos-img/511/carousel3.jpg"),
      require("./assets/images/productos-img/511/carousel4.jpg"),
      require("./assets/images/productos-img/511/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/y9YAjStlD8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 512,
    item: 513,
    name: "JENGA MEDIANO",
    image: require("./assets/images/productos-img/511/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "45 cm de alto.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/511/principal.jpg"),
      require("./assets/images/productos-img/511/carousel2.jpg"),
      require("./assets/images/productos-img/511/carousel3.jpg"),
      require("./assets/images/productos-img/511/carousel4.jpg"),
      require("./assets/images/productos-img/511/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/y9YAjStlD8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 513,
    item: 514,
    name: "JENGA GRANDE",
    image: require("./assets/images/productos-img/513/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "90 cm de alto con base de madera.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/513/principal.jpg"),
      require("./assets/images/productos-img/513/carousel2.jpg"),
      require("./assets/images/productos-img/513/carousel3.jpg"),
      require("./assets/images/productos-img/513/carousel4.jpg"),
      require("./assets/images/productos-img/513/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/w4OV0IiWpcs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 514,
    item: 515,
    name: "PASAFICHAS",
    image: require("./assets/images/productos-img/514/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Gana quien se quede sin fichas de su lado.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/514/principal.jpg"),
      require("./assets/images/productos-img/514/carousel2.jpg"),
      require("./assets/images/productos-img/514/carousel3.jpg"),
      require("./assets/images/productos-img/514/carousel4.jpg"),
      require("./assets/images/productos-img/514/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 515,
    item: 516,
    name: "PASAFICHAS",
    image: require("./assets/images/productos-img/514/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Gana quien se quede sin fichas de su lado.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/514/principal.jpg"),
      require("./assets/images/productos-img/514/carousel2.jpg"),
      require("./assets/images/productos-img/514/carousel3.jpg"),
      require("./assets/images/productos-img/514/carousel4.jpg"),
      require("./assets/images/productos-img/514/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 516,
    item: 517,
    name: "PASAFICHAS",
    image: require("./assets/images/productos-img/514/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Gana quien se quede sin fichas de su lado.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/514/principal.jpg"),
      require("./assets/images/productos-img/514/carousel2.jpg"),
      require("./assets/images/productos-img/514/carousel3.jpg"),
      require("./assets/images/productos-img/514/carousel4.jpg"),
      require("./assets/images/productos-img/514/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 517,
    item: 518,
    name: "PASAFICHAS DOBLE GIGANTE",
    image: require("./assets/images/productos-img/517/principal.jpg"),
    size: "1 x 1,20",
    age: "> 5 años",
    descripcion:
      "Gana el que se queda sin fichas de su lado. Se puede jugar de a 4 jugadores.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/517/principal.jpg"),
      require("./assets/images/productos-img/517/carousel2.jpg"),
      require("./assets/images/productos-img/517/carousel3.jpg"),
      require("./assets/images/productos-img/517/carousel4.jpg"),
      require("./assets/images/productos-img/517/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-LbU67XlPgo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 518,
    item: 519,
    name: "PASAFICHAS DOBLE GIGANTE",
    image: require("./assets/images/productos-img/517/principal.jpg"),
    size: "1 x 1,20",
    age: "> 5 años",
    descripcion:
      "Gana el que se queda sin fichas de su lado. Se puede jugar de a 4 jugadores.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/517/principal.jpg"),
      require("./assets/images/productos-img/517/carousel2.jpg"),
      require("./assets/images/productos-img/517/carousel3.jpg"),
      require("./assets/images/productos-img/517/carousel4.jpg"),
      require("./assets/images/productos-img/517/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-LbU67XlPgo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 519,
    item: 520,
    name: "JENGA GRANDE",
    image: require("./assets/images/productos-img/513/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "90 cm de alto con base de madera.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/513/principal.jpg"),
      require("./assets/images/productos-img/513/carousel2.jpg"),
      require("./assets/images/productos-img/513/carousel3.jpg"),
      require("./assets/images/productos-img/513/carousel4.jpg"),
      require("./assets/images/productos-img/513/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/w4OV0IiWpcs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 521,
    item: 522,
    name: "CANCHA FUTBOL TENIS",
    image: require("./assets/images/productos-img/521/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Incluye red y líneas perimetrales. No incluye pelota.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/521/principal.jpg"),
      require("./assets/images/productos-img/521/carousel2.jpg"),
      require("./assets/images/productos-img/521/carousel3.jpg"),
      require("./assets/images/productos-img/521/carousel4.jpg"),
      require("./assets/images/productos-img/521/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9dmOjfnexow"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 522,
    item: 523,
    name: "CANCHA DE VOLEY",
    image: require("./assets/images/productos-img/522/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion: "Incluye red y líneas perimetrales. Incluye pelota.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/522/principal.jpg"),
      require("./assets/images/productos-img/522/carousel2.jpg"),
      require("./assets/images/productos-img/522/carousel3.jpg"),
      require("./assets/images/productos-img/522/carousel4.jpg"),
    ],
  },
  {
    id: 523,
    item: 524,
    name: "ARCOS DE FUTBOL",
    image: require("./assets/images/productos-img/523/principal.jpg"),
    size: "1,40 x 1,70",
    age: "> 5 años",
    descripcion: "2 arcos de fútbol 1,40 x 1,70. No incluye pelota.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/523/principal.jpg"),
      require("./assets/images/productos-img/523/carousel2.jpg"),
      require("./assets/images/productos-img/523/carousel3.jpg"),
      require("./assets/images/productos-img/523/carousel4.jpg"),
      require("./assets/images/productos-img/523/carousel5.jpg"),
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZUsR-Rk-Cns"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 524,
    item: 525,
    name: "JUEGO DE PUNTERIA",
    image: require("./assets/images/productos-img/524/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Cornhole - Baggo.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/524/principal.jpg"),
      require("./assets/images/productos-img/524/carousel2.jpg"),
      require("./assets/images/productos-img/524/carousel3.jpg"),
      require("./assets/images/productos-img/524/carousel4.jpg"),
      require("./assets/images/productos-img/524/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 525,
    item: 526,
    name: "JUEGO DE PUNTERIA",
    image: require("./assets/images/productos-img/524/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Cornhole - Baggo.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/524/principal.jpg"),
      require("./assets/images/productos-img/524/carousel2.jpg"),
      require("./assets/images/productos-img/524/carousel3.jpg"),
      require("./assets/images/productos-img/524/carousel4.jpg"),
      require("./assets/images/productos-img/524/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 526,
    item: 527,
    name: "JUEGO DE PUNTERIA",
    image: require("./assets/images/productos-img/524/principal.jpg"),
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Cornhole - Baggo.",
    filtro: ["juegos"],
    carouselImages: [
      require("./assets/images/productos-img/524/principal.jpg"),
      require("./assets/images/productos-img/524/carousel2.jpg"),
      require("./assets/images/productos-img/524/carousel3.jpg"),
      require("./assets/images/productos-img/524/carousel4.jpg"),
      require("./assets/images/productos-img/524/carousel5.jpg"),
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  // --------------------- PLAZA BLANDA --------------------------
  {
    id: 600,
    item: 601,
    name: "DIDACTICO 1",
    image: require("./assets/images/productos-img/600/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion:
      "+ 4 pisos de goma eva 1x1 + 1 pizarrón doble panel + 1 sube y baja + 1 perrito saltarín + 1 tobogán + 1 mesa con juegos didácticos.",
    filtro: ["plaza blanda"],
    carouselImages: [
      require("./assets/images/productos-img/600/principal.jpg"),
      require("./assets/images/productos-img/600/carousel2.jpg"),
      require("./assets/images/productos-img/600/carousel3.jpg"),
      require("./assets/images/productos-img/600/carousel4.jpg"),
      require("./assets/images/productos-img/600/carousel5.jpg"),
    ],
  },
  {
    id: 601,
    item: 602,
    name: "DIDACTICO 2",
    image: require("./assets/images/productos-img/601/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion:
      "+ 4 pisos de goma eva 1x1 + 1 pelotero 1x1 + 1 pizarrón doble panel + 1 sube y baja + 1 perrito inflable + 1 dino saltarín + 1 tobogán + 1 mesa con juegos didácticos + 1 mesa con cubo de 5 actividades + cubo multijuego de 4 juegos y prono",
    filtro: ["plaza blanda"],
    carouselImages: [
      require("./assets/images/productos-img/601/principal.jpg"),
      require("./assets/images/productos-img/601/carousel2.jpg"),
      require("./assets/images/productos-img/601/carousel3.jpg"),
      require("./assets/images/productos-img/601/carousel4.jpg"),
      require("./assets/images/productos-img/601/carousel5.jpg"),
    ],
  },
  {
    id: 602,
    item: 603,
    name: "PLAZA KIDS",
    image: require("./assets/images/productos-img/602/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion:
      "+ 1 Gazebo (bonificado si se arma al aire libre, no intercambiable por otro artículo) + 4 pisos de goma eva + 1 pelotero 1x1 + 1 pizarrón doble panel + 1 sube y baja + 2 zapatillas andadoras + 1 perrito inflable + 1 dino saltarín + 1 tobogán + 1 mesa con juegos didácticos + 1 mesa con cubo de 5 actividades + cubo multijuego de 4 juegos y prono + gazebo bonificado si se arma al aire libre (no intercambiable por otro articulo)",
    filtro: ["plaza blanda"],
    carouselImages: [
      require("./assets/images/productos-img/602/principal.jpg"),
      require("./assets/images/productos-img/602/carousel2.jpg"),
      require("./assets/images/productos-img/602/carousel3.jpg"),
      require("./assets/images/productos-img/602/carousel4.jpg"),
      require("./assets/images/productos-img/602/carousel5.jpg"),
    ],
  },
  {
    id: 603,
    item: 604,
    name: "PLAZA MONTESORI",
    image: require("./assets/images/productos-img/603/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion:
      "+ pelotero + tobogán + escalador + triángulo + balancín + sube y baja + caballito mecedor + pata pata + carpita + piso de goma eva",
    filtro: ["plaza blanda"],
    carouselImages: [
      require("./assets/images/productos-img/603/principal.jpg"),
      require("./assets/images/productos-img/603/carousel2.jpg"),
      require("./assets/images/productos-img/603/carousel3.jpg"),
      require("./assets/images/productos-img/603/carousel4.jpg"),
      require("./assets/images/productos-img/603/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 604,
    item: 605,
    name: "PELOTERO SOFT",
    image: require("./assets/images/productos-img/604/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion: "Pelotero de 2x2 metros con pelotitas color pastel y tobogán.",
    filtro: ["plaza blanda"],
    carouselImages: [
      require("./assets/images/productos-img/604/principal.jpg"),
      require("./assets/images/productos-img/604/carousel2.jpg"),
      require("./assets/images/productos-img/604/carousel3.jpg"),
      require("./assets/images/productos-img/604/carousel4.jpg"),
      require("./assets/images/productos-img/604/carousel5.jpg"),
    ],
    consultar: true,
  },
  // COMBOS
  {
    id: 700,
    item: [6, 504],
    name: "COMBO INFANTIL",
    image: require("./assets/images/productos-img/700/principal.jpg"),
    size: "2,50x4x2",
    age: "2-5 años",
    descripcion: "Combo inflable Infantil + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/700/principal.jpg"),
      require("./assets/images/productos-img/700/carousel2.jpg"),
      require("./assets/images/productos-img/700/carousel3.jpg"),
      require("./assets/images/productos-img/700/carousel4.jpg"),
      require("./assets/images/productos-img/700/carousel5.jpg"),
    ],
  },
  {
    id: 701,
    item: [10, 503],
    name: "COMBO FANTASIA",
    image: require("./assets/images/productos-img/701/principal.jpg"),
    size: "4x5x2,50",
    age: "2-9 años",
    descripcion: "Combo inflable Fantasía + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/701/principal.jpg"),
      require("./assets/images/productos-img/701/carousel2.jpg"),
      require("./assets/images/productos-img/701/carousel3.jpg"),
      require("./assets/images/productos-img/701/carousel4.jpg"),
      require("./assets/images/productos-img/701/carousel5.jpg"),
    ],
  },
  {
    id: 702,
    item: [7, 502],
    name: "COMBO MAGICO",
    image: require("./assets/images/productos-img/702/principal.jpg"),
    size: "3x5x2,30",
    age: "2-9 años",
    descripcion: "Combo inflable Mágico + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/702/principal.jpg"),
      require("./assets/images/productos-img/702/carousel2.jpg"),
      require("./assets/images/productos-img/702/carousel3.jpg"),
      require("./assets/images/productos-img/702/carousel4.jpg"),
      require("./assets/images/productos-img/702/carousel5.jpg"),
    ],
  },
  {
    id: 703,
    item: [9, 520, 527],
    name: "COMBO ALEGRIA",
    image: require("./assets/images/productos-img/703/principal.jpg"),
    size: "4x5",
    age: "2-9 años",
    descripcion:
      "Combo inflable Alegría + Juego de Puntería + Pasaficha Grande.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/703/principal.jpg"),
      require("./assets/images/productos-img/703/carousel2.jpg"),
      require("./assets/images/productos-img/703/carousel3.jpg"),
      require("./assets/images/productos-img/703/carousel4.jpg"),
      require("./assets/images/productos-img/703/carousel5.jpg"),
    ],
  },
  {
    id: 704,
    item: [8, 517, 526, 503],
    name: "COMBO ELEFANTE",
    image: require("./assets/images/productos-img/704/principal.jpg"),
    size: "3,50x5x2,20",
    age: "2-9 años",
    descripcion:
      "Combo inflable Elefante + Metegol + Juego de Puntería + Pasaficha Chico.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/704/principal.jpg"),
      require("./assets/images/productos-img/704/carousel2.jpg"),
      require("./assets/images/productos-img/704/carousel3.jpg"),
      require("./assets/images/productos-img/704/carousel4.jpg"),
      require("./assets/images/productos-img/704/carousel5.jpg"),
    ],
  },
  {
    id: 705,
    item: 706,
    name: "COMBO PASTEL",
    image: require("./assets/images/productos-img/705/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Candy con pelotero + Plaza temática Pastel.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/705/principal.jpg"),
      require("./assets/images/productos-img/705/carousel2.jpg"),
      require("./assets/images/productos-img/705/carousel3.jpg"),
      require("./assets/images/productos-img/705/carousel4.jpg"),
      require("./assets/images/productos-img/705/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 706,
    item: 707,
    name: "COMBO CAMION",
    image: require("./assets/images/productos-img/706/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Camión + Plaza temática Taller Mecánico.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/706/principal.jpg"),
      require("./assets/images/productos-img/706/carousel2.jpg"),
      require("./assets/images/productos-img/706/carousel3.jpg"),
      require("./assets/images/productos-img/706/carousel4.jpg"),
      require("./assets/images/productos-img/706/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 707,
    item: 708,
    name: "COMBO GRANJA",
    image: require("./assets/images/productos-img/707/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Bosque + Plaza temática Granja.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/707/principal.jpg"),
      require("./assets/images/productos-img/707/carousel2.jpg"),
      require("./assets/images/productos-img/707/carousel3.jpg"),
      require("./assets/images/productos-img/707/carousel4.jpg"),
      require("./assets/images/productos-img/707/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 708,
    item: 709,
    name: "COMBO SAFARI",
    image: require("./assets/images/productos-img/708/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Safari + Plaza temática Safari.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/708/principal.jpg"),
      require("./assets/images/productos-img/708/carousel2.jpg"),
      require("./assets/images/productos-img/708/carousel3.jpg"),
      require("./assets/images/productos-img/708/carousel4.jpg"),
      require("./assets/images/productos-img/708/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 709,
    item: [41, 42, 411, 412],
    name: "COMBO BLANCO",
    image: require("./assets/images/productos-img/709/principal.jpg"),
    size: "-",
    age: "2-5 años",
    descripcion:
      "Combo inflable blanco con pelotero + miniliving blanco liso para 20 chicos.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/709/principal.jpg"),
      require("./assets/images/productos-img/709/carousel2.jpg"),
      require("./assets/images/productos-img/709/carousel3.jpg"),
      require("./assets/images/productos-img/709/carousel4.jpg"),
      require("./assets/images/productos-img/709/carousel5.jpg"),
    ],
  },
  {
    id: 710,
    item: [13, 24, 43],
    name: "COMBO SELVATICO",
    image: require("./assets/images/productos-img/710/principal.jpg"),
    size: "-",
    age: "2-9 años",
    descripcion: "Combo inflable Safari + inflable Bosque + inflable Jungla.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/710/principal.jpg"),
      require("./assets/images/productos-img/710/carousel2.jpg"),
      require("./assets/images/productos-img/710/carousel3.jpg"),
      require("./assets/images/productos-img/710/carousel4.jpg"),
      require("./assets/images/productos-img/710/carousel5.jpg"),
    ],
  },
  {
    id: 711,
    item: [43, 503],
    name: "COMBO SAFARI + METEGOL",
    image: require("./assets/images/productos-img/711/principal.jpg"),
    size: "-",
    age: "2-9 años",
    descripcion: "Combo inflable Safari + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/711/principal.jpg"),
      require("./assets/images/productos-img/711/carousel2.jpg"),
      require("./assets/images/productos-img/711/carousel3.jpg"),
      require("./assets/images/productos-img/711/carousel4.jpg"),
      require("./assets/images/productos-img/711/carousel5.jpg"),
    ],
  },
  {
    id: 712,
    item: [43, 408],
    name: "COMBO SAFARI + MINILIVING BLANCO (20)",
    image: require("./assets/images/productos-img/712/principal.jpg"),
    size: "4x4x5",
    age: "2-9 años",
    descripcion: "Combo inflable Safari + Mini Living blanco para 20",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/712/principal.jpg"),
      require("./assets/images/productos-img/712/carousel2.jpg"),
      require("./assets/images/productos-img/712/carousel3.jpg"),
      require("./assets/images/productos-img/712/carousel4.jpg"),
      require("./assets/images/productos-img/712/carousel5.jpg"),
    ],
  },
  {
    id: 713,
    item: [43, 4, 408],
    name: "COMBO SAFARI CON PELOTERO + MINILIVING BLANCO (20)",
    image: require("./assets/images/productos-img/713/principal.jpg"),
    size: "6x4x5",
    age: "2-9 años",
    descripcion:
      "Combo inflable Safari con Pelotero + Mini Living blanco para 20",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/713/principal.jpg"),
      require("./assets/images/productos-img/713/carousel2.jpg"),
      require("./assets/images/productos-img/713/carousel3.jpg"),
      require("./assets/images/productos-img/713/carousel4.jpg"),
      require("./assets/images/productos-img/713/carousel5.jpg"),
    ],
  },
  {
    id: 714,
    item: [29, 31],
    name: "COMBO DEPORTIVO 1",
    image: require("./assets/images/productos-img/714/principal.jpg"),
    size: "-",
    age: "5-9 años",
    descripcion:
      "Combo inflable Cancha de Futbol 4x6 + Inflable Arco Ataja Penales.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/714/principal.jpg"),
      require("./assets/images/productos-img/714/carousel2.jpg"),
      require("./assets/images/productos-img/714/carousel3.jpg"),
      require("./assets/images/productos-img/714/carousel4.jpg"),
      require("./assets/images/productos-img/714/carousel5.jpg"),
    ],
  },
  {
    id: 715,
    item: [30, 31],
    name: "COMBO DEPORTIVO 2",
    image: require("./assets/images/productos-img/715/principal.jpg"),
    size: "-",
    age: "7-13 años",
    descripcion:
      "Combo inflable Cancha de Futbol 4x9 + Inflable Arco Ataja Penales.",
    filtro: ["combos"],
    carouselImages: [
      require("./assets/images/productos-img/715/principal.jpg"),
      require("./assets/images/productos-img/715/carousel2.jpg"),
      require("./assets/images/productos-img/715/carousel3.jpg"),
      require("./assets/images/productos-img/715/carousel4.jpg"),
      require("./assets/images/productos-img/715/carousel5.jpg"),
    ],
  },
  // COMBOS
  {
    id: 800,
    item: 801,
    name: "CATERING",
    image: require("./assets/images/productos-img/800/principal.jpg"),
    size: "-",
    age: "-",
    descripcion:
      "+ finger food + cortes de carne en sandwich + cortes de carne especiales + pernil + mesa dulce",
    filtro: ["catering"],
    carouselImages: [
      require("./assets/images/productos-img/800/principal.jpg"),
      require("./assets/images/productos-img/800/carousel2.jpg"),
      require("./assets/images/productos-img/800/carousel3.jpg"),
      require("./assets/images/productos-img/800/carousel4.jpg"),
      require("./assets/images/productos-img/800/carousel5.jpg"),
    ],
    consultar: true,
  },
  {
    id: 801,
    item: 802,
    name: "TARDE DE MATES",
    image: require("./assets/images/productos-img/801/principal.jpg"),
    size: "-",
    age: "-",
    descripcion: "+ Tarde de Mate + Coffe Break + Tapeo + Brunch",
    filtro: ["catering"],
    carouselImages: [
      require("./assets/images/productos-img/801/principal.jpg"),
      require("./assets/images/productos-img/801/carousel2.jpg"),
    ],
    consultar: true,
  },
];
export default products;

export const sortProducts = [1, 2, 3];
